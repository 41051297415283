import { EditorSelectionType, useEditorContext } from "../../contexts/EditorContext";
import ActorPreviewPanel from "./Actors/ActorPreviewPanel";
import AssetPreviewPanel from "./Assets/AssetPreviewPanel";
import LevelEditor from "./Levels/LevelEditor";

const MainCanvasPanel = () => {
    const {currentSelection} = useEditorContext();

    let canvasPanelContent = null;
    if(currentSelection) {
        switch(currentSelection.type) {
            case EditorSelectionType.ANIMATION:
            case EditorSelectionType.ACTOR:
                canvasPanelContent = <ActorPreviewPanel />;
                break;
            case EditorSelectionType.ASSET:
                canvasPanelContent = <AssetPreviewPanel />;
                break;
            default:
                canvasPanelContent = <LevelEditor />
        }
    }

    return (
    <>
        {canvasPanelContent}
    </>
    );
}

export default MainCanvasPanel;