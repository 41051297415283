import { AssetDef, DataFormat, LayerDef, PackedData } from "../types";

const process2bppBuffer = (buffer: Uint8Array, width: number, height: number) => {
    const spriteBuffer = [];

    for(let y = 0; y < height; y++) {
        for(let x = 0; x < width; x++) {
            const offset = y * width + x;

            const bits = buffer[offset >> 2];
            const val = (bits >> ((offset%4)<<1)) & ~(~0<<2);
            spriteBuffer.push(val);
        }
    }

    return spriteBuffer;
}

const unpack1bppBuffer = (buffer: Uint8Array, width: number, height: number): number[] => {
    const spriteBuffer = [];
    for(let y = 0; y < height; y++) {
        for(let x = 0; x < width; x++) {
            const offset = y * width + x;

            const bits = buffer[offset >> 3];
            const val = (bits >> (offset%8)) & 1;
            spriteBuffer.push(val);
        }
    }

    return spriteBuffer;
}

const getPacked1bppBytes = (entries: number[]) => {
    //group into groups of 8
    let chunks: number[][] = [];
    for(let i =0; i < entries.length; i+=8) {
        chunks.push(entries.slice(i, i+8));
    }

    //pack into bytes
    const bytes: number[] = [];
    for(const chunk of chunks) {
        let byte = 0;
        for(let i = chunk.length-1; i >= 0; i--) {
            byte = (byte<<1)|chunk[i];
        }
        bytes.push(byte);
    }

    return bytes;
}

const getPacked2bppBytes = (entries: number[]) => {
    //first group into groups of 4
    let chunks: number[][] = [];
    for(let i = 0; i < entries.length; i+=4) {
        const tempArray = entries.slice(i, i + 4);
        chunks.push(tempArray);
    }

    //Now pack into bytes
    const bytes: number[] = [];
    for(const chunk of chunks) {
        let byte = 0;
        for(let i = chunk.length-1; i >= 0; i--) {
            byte = (byte<<2)|chunk[i];
        }
        bytes.push(byte);
    }
    return bytes;
}

const getPackedDataFromRawData = (rawData: number[]): PackedData => {
    let maxVal = 0;
    for(const val of rawData) {
        if(val && val > maxVal) {
            maxVal = val;
        }
    }
    let packedBytes: number[] = [];
    let is2bpp = false;
    if(maxVal <= 1) {
        //1bpp
        packedBytes = getPacked1bppBytes(rawData);
        is2bpp = false;
    } else {
        //2bpp
        packedBytes = getPacked2bppBytes(rawData);
        is2bpp = true;
    }

    return {
        data: packedBytes,
        format: is2bpp ? DataFormat.BPP2 : DataFormat.BPP1
    };
}

//Export layer to data file format.
//Format is:
//1 byte: Layer Width
//1 byte: Layer Height
//1 byte: Pixel Width
//1 byte: Pixel Height
//1 byte: OffsetX
//1 byte: OffsetY
//1 byte: Settings
//  - bit1: is2bpp (0 = 1bpp, 1 = 2bpp)
const exportLayer = (layer: LayerDef) => {
    console.log(layer);
    const packedData = getPackedDataFromRawData(layer.assetMap);
    
    const finalData: number[] = [
        packedData.format === DataFormat.BPP2 ? 1 : 0,
        ...Array.from(packedData.data)
    ];
    console.log(`Exporting Layer ${layer.key} - `);
    console.log(finalData);

    //save file
    downloadData(new Uint8Array(finalData), `LAYER_${layer.key}.dat`);

    return Array.from(packedData.data);
}

const exportAssets = (assets: AssetDef[]) => {
    let finalData: number[] = [];
    for(const asset of assets) {
        const dataOffset = finalData.length;
        console.log(asset.data);
        finalData = finalData.concat(asset.data);
        console.log(`${asset.key} - Offset: ${dataOffset}        wxh: ${asset.width}x${asset.height}`);
    }
    console.log(finalData);
    downloadData(new Uint8Array(finalData), 'UNCOMPRESSED_PACKED_ASSETS.dat');
}

const importLayer = (data: Uint8Array): LayerDef => {
    const layerSize = {width: data[0], height: data[1]};
    const is2Bpp = data[4] === 1;
    let assetMap: number[] = [];

    if(is2Bpp) {
        
    } else {
        assetMap = unpack1bppBuffer(new Uint8Array(data.buffer, 5, data.length - 5), layerSize.width, layerSize.height);
    }

    return {
        assetMap,
        key: 'import',
        tag: ''
    };
}

const downloadData = (data: Uint8Array, fileName: string) => {
    const blob = new Blob([data], {type: 'application/octet-stream'});
    const link = document.createElement('a');
    link.href=window.URL.createObjectURL(blob);
    link.download=fileName;
    link.click();
}


const downloadJson = (jsonString: string, fileName: string) => {
    const blob = new Blob([jsonString], {type: 'text/json;charset=utf-8'});
    const link = document.createElement('a');
    link.href=window.URL.createObjectURL(blob);
    link.download=fileName;
    link.click();
}

const DataProcessor = {
    exportLayer,
    process2bppBuffer,
    importLayer,
    getPacked2bppBytes,
    getPackedDataFromRawData,
    downloadData,
    downloadJson,
    exportAssets
};

export default DataProcessor;