import { Button, Input, InputGroup, Select, Stack, Tag, Text } from "@chakra-ui/react";
import { useLevelContext } from "../../../contexts/LevelContext";
import { LayerKey } from "../../../types";

interface PropertiesPanelProps {
    selectedLevelIndex: number;
}

const LevelProperties = (props: PropertiesPanelProps) => {
    const {levels, updateLevel, removeLevel, cloneLevel, layers} = useLevelContext();

    const selectedLevel = levels[props.selectedLevelIndex];

    const groundLayerOptions = [];
    const obstacleLayerOptions = [];
    for(let i =0; i < layers.length; i++) {
        if(layers[i].tag === 'ground') {
            groundLayerOptions.push(<option value={layers[i].key} key={i}>{layers[i].key}</option>);
        } else if(layers[i].tag === 'obstacle') {
            obstacleLayerOptions.push(<option value={layers[i].key} key={i}>{layers[i].key}</option>);
        }
    }

    if(!selectedLevel) {
        return (<Text>Level not found LevelIndex: {props.selectedLevelIndex}</Text>);
    }

    const updateKey = (val: string) => {
        let updatedLevel = selectedLevel;
        updatedLevel.key = val;
        updateLevel(props.selectedLevelIndex, updatedLevel);
    }

    const updateGroundLayer = (layerKey: LayerKey) => {
        let updatedLevel = selectedLevel;
        updatedLevel.groundLayerKey = layerKey;
        updateLevel(props.selectedLevelIndex, updatedLevel);
    }

    const updateObstacleLayer = (layerKey: LayerKey) => {
        let updatedLevel = selectedLevel;
        updatedLevel.obstacleLayerKey = layerKey;
        updateLevel(props.selectedLevelIndex, updatedLevel);
    }

    return (
    <Stack m={4}  spacing={4}>
        <Input placeholder='Key' value={selectedLevel.key} onChange={(e) => {updateKey(e.target.value)}} />
        <InputGroup>
            <Tag w={200}>Ground Layer</Tag>
            <Select placeholder="Select Layer" value={selectedLevel.groundLayerKey} onChange={(e) => updateGroundLayer(e.target.value) }>
            {groundLayerOptions}
            </Select>
        </InputGroup>
        <InputGroup>
            <Tag w={200}>Obstacle Layer</Tag>
            <Select placeholder="Select Layer" value={selectedLevel.obstacleLayerKey} onChange={(e) => updateObstacleLayer(e.target.value) }>
            {obstacleLayerOptions}
            </Select>
        </InputGroup>
        <Button colorScheme='red' onClick={() => removeLevel(props.selectedLevelIndex)}>Delete Level</Button>
        <Button colorScheme='green' onClick={() => cloneLevel(props.selectedLevelIndex)}>Clone Level</Button>
    </Stack>);
}

export default LevelProperties;