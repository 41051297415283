import { Input, InputGroup, Stack, Text, Image, Button, Tag } from "@chakra-ui/react";
import { useAssetContext } from "../../../contexts/AssetContext";
import { useEditorContext } from "../../../contexts/EditorContext";
import { useLevelContext } from "../../../contexts/LevelContext";
import { EmptyLayerTypeDef } from "../../../types";

interface PropertiesPanelProps {
    selectedLayerIndex: number;
}

const LayerProperties = (props: PropertiesPanelProps) => {
    const {assets} = useAssetContext();
    const {layers, getLayerTypeByTag, updateLayer, removeLayer, cloneLayer} = useLevelContext();
    const {selectedLayerSlot, setSelectedLayerSlot} = useEditorContext();

    const selectedLayer = layers[props.selectedLayerIndex];

    if(!selectedLayer) {
        return (<Text>Layer not found LayerId: {props.selectedLayerIndex}</Text>);
    }

    const selectedLayerType = getLayerTypeByTag(selectedLayer.tag) ?? EmptyLayerTypeDef;

    const asset1 = assets[selectedLayerType.assetSlot1];
    const asset2 = assets[selectedLayerType.assetSlot2];
    const asset3 = assets[selectedLayerType.assetSlot3];

    const assetOptions = [];
    for(let i =0; i < assets.length; i++) {
        assetOptions.push(<option value={i}>{assets[i].key}</option>);
    } 

    const updateKey = (val: string) => {
        let updatedLayer = selectedLayer;
        updatedLayer.key = val;
        updateLayer(props.selectedLayerIndex, updatedLayer);
    }
    return (
    <Stack m={4}  spacing={4}>
        <Input placeholder='Key' value={selectedLayer.key} onChange={(e) => {updateKey(e.target.value)}} />
        <InputGroup onClick={() => setSelectedLayerSlot(1)}>
            <Tag w={200}  colorScheme={selectedLayerSlot === 1 ? 'blue' : 'gray'}>Asset Slot 1</Tag>
            {asset1 && <Image src={asset1.imagePreviewUri} objectFit='contain' sx={{minH: '40px'}} />}
            <Text>{asset1?.key ?? 'None'}</Text>
        </InputGroup>
        <InputGroup onClick={() => setSelectedLayerSlot(2)}>
            <Tag w={200}  colorScheme={selectedLayerSlot === 2 ? 'blue' : 'gray'}>Asset Slot 2</Tag>
            {asset2 && <Image src={asset2.imagePreviewUri} objectFit='contain' sx={{minH: '40px'}} />}
            <Text>{asset2?.key ?? 'None'}</Text>
        </InputGroup>
        <InputGroup  onClick={() => setSelectedLayerSlot(3)}>
            <Tag w={200} colorScheme={selectedLayerSlot === 3 ? 'blue' : 'gray'}>Asset Slot 3</Tag>
            {asset3 && <Image src={asset3.imagePreviewUri} objectFit='contain' sx={{minH: '40px'}} />}
            <Text>{asset3?.key ?? 'None'}</Text>
        </InputGroup>
        <Button colorScheme='red' onClick={() => removeLayer(selectedLayer.key)}>Delete Layer</Button>
        <Button colorScheme='green' onClick={() => cloneLayer(selectedLayer.key)}>Clone Layer</Button>
    </Stack>);
}

export default LayerProperties;