import { Menu, MenuItem } from "@blueprintjs/core";
import { ContextMenu2 } from "@blueprintjs/popover2";
import { Flex } from "@chakra-ui/react";
import { useState } from "react";
import { useActorContext } from "../../../contexts/ActorContext";
import { EditorSelectionType, useEditorContext } from "../../../contexts/EditorContext";
import NewAnimationDialog from "./NewAnimationDialog";

const AnimationList = () => {
    const [newAnimDialog, setNewAnimDialog] = useState(false);
    const {currentSelection, setSelection} = useEditorContext();
    const {animations} = useActorContext();

    const onAnimClicked = (animationId: number) => {
        setSelection(EditorSelectionType.ANIMATION, animationId);
    }

    return (
    <ContextMenu2 style={{height: '100%'}} content={<Menu>
        <MenuItem icon="plus" text="New Animation..." onClick={() => setNewAnimDialog(true)} />
    </Menu>}>
    <Flex flexDir='column' sx={{display: 'flex', flexDirection: 'column'}} height='100%' flex={1}>
        <Menu>
            {animations.map((anim, i) => (
                <MenuItem key={i} active={currentSelection?.type === EditorSelectionType.ANIMATION && i === currentSelection.id} 
                    text={anim.key} onClick={() => onAnimClicked(i)} />
            ))}
            <MenuItem icon='plus' text='New Animation...' onClick={() => setNewAnimDialog(true)}/>
    </Menu>

        {newAnimDialog && <NewAnimationDialog isOpen={newAnimDialog} onClose={() => setNewAnimDialog(false)} />}
    </Flex>
    </ContextMenu2>
  );
}

export default AnimationList;