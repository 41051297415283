import { Dialog } from "@blueprintjs/core";
import { Image, Grid, GridItem, Text, VStack, Button } from "@chakra-ui/react";
import { useState } from "react";
import { useActorContext } from "../../../contexts/ActorContext";
import { useAssetContext } from "../../../contexts/AssetContext";
import { AnimationKey } from "../../../types";

interface SelectAnimationDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onAnimationsSelected: (animKeys: AnimationKey[]) => void;
}

const SelectAnimationDialog = (props: SelectAnimationDialogProps) => {
    const {getAssetByKey} = useAssetContext();
    const {animations} = useActorContext();

    const [hoveredId, setHoveredId] = useState(-1);
    const [selectedAnimKeys, setSelectedAnimKeys] = useState<AnimationKey[]>([]);


    const onAnimClicked = (key: AnimationKey) => {
        const existingIndex = selectedAnimKeys.indexOf(key);
        if(existingIndex >= 0) {
            setSelectedAnimKeys(selectedAnimKeys => selectedAnimKeys.filter(r => r !== key));
        } else {
            setSelectedAnimKeys(selectedAnimKeys => [...selectedAnimKeys, key]);
        }
    }

    const onMouseEnter = (id: number) => {
        setHoveredId(id);
    }

    const onMouseExit = (id: number) => {
        if(hoveredId === id) {
            setHoveredId(-1);
        }
    }

    const confirmSelection = () => {
        props.onAnimationsSelected(selectedAnimKeys);
    }

    return (
        <Dialog className='bp3-dark' title='Select Animations' isOpen={props.isOpen} onClose={props.onClose}>
            <Grid templateColumns="repeat(5, 1fr)" gap={2}>
                {animations.map((r, index) => (
                    <GridItem display='flex' onClick={() => onAnimClicked(r.key)} key={index}
                        style={{border: hoveredId === index || selectedAnimKeys.indexOf(r.key) >= 0 ? '4px black solid' : 'none'}}
                        onMouseEnter={() => onMouseEnter(index)} onMouseLeave={() => onMouseExit(index)}>
                        <VStack>
                        <Image m='auto' w='100%' maxH='200px' maxW='200px' 
                            src={r.frameAssetKeys && r.frameAssetKeys[0] && getAssetByKey(r.frameAssetKeys[0])?.imagePreviewUri} objectFit='cover' />
                        <Text>{r.key}</Text>
                        </VStack>
                    </GridItem>
                ))}
            </Grid>
            <Button onClick={confirmSelection}>Select Animations</Button> 
        </Dialog>
    );
}

export default SelectAnimationDialog;