import { Dialog, FileInput } from "@blueprintjs/core";
import { Button, Stack, Tag, Image, HStack, InputGroup, InputLeftAddon, NumberInput, NumberInputField, VStack, Input } from "@chakra-ui/react";
import { SyntheticEvent, useState } from "react";
import { useAssetContext } from "../../../contexts/AssetContext";
// import { useAssetContext } from "../../../contexts/AssetContext";
import DataProcessor from "../../../utils/DataProcessor";
import { getRawDataFromRgb, getRgbaDataFromImage, ImportedImageData, ProcessSpritesheet } from "../../../utils/ImageProcessor";

interface ImportSpritesheetDialogProps {
    isOpen: boolean;
    onClose: () => void
}

const ImportSpritesheetDialog = (props: ImportSpritesheetDialogProps) => {
    const [rawImageData, setRawImageData] = useState<ImportedImageData | null>(null);

    const [sheetSize, setSheetSize] = useState({width: 8, height: 6});
    const [tileData, setTileData] = useState<ImportedImageData[]>([]);

    const [tileNames, setTileNames] = useState<any>({});

    const {importAsset} = useAssetContext();

    const onUploadImage = (e: SyntheticEvent) => {
        const files = (e.target as any).files;
        if(files.length !== 1) {
            return;
        }
        const file = files[0];
        getRgbaDataFromImage(file, (data) => {
            setRawImageData(data);
            const tileData = ProcessSpritesheet(data, sheetSize);
            onSpritesheetProcessed(tileData);
        })
    }

    const onChangeSheetSize = (width: number, height: number) => {
        setSheetSize({width, height});
        if(rawImageData) {
            const tileData = ProcessSpritesheet(rawImageData, sheetSize);
            onSpritesheetProcessed(tileData);
        }
    }

    const onSpritesheetProcessed = (tiles: ImportedImageData[]) => {
        setTileData(tiles);
    }

    const onUpdateName = (tileId: number, name: string) => {
        const tileNamesCopy = {...tileNames};
        tileNamesCopy[tileId] = name;
        setTileNames(tileNamesCopy);
    }

    const onImportAsset = () => {
        //Create Asset for each tile with name
        for(const [k] of Object.entries(tileNames)) {
            const tileIndex = parseInt(k);
            const rawData = getRawDataFromRgb(tileData[tileIndex].imageRgbaData, []);
            const packedData = DataProcessor.getPackedDataFromRawData(rawData);
            importAsset({
                data: packedData.data,
                dataFormat: packedData.format,
                imagePreviewUri: tileData[tileIndex].dataUri,
                key: tileNames[tileIndex],
                height: tileData[tileIndex].size.height,
                width: tileData[tileIndex].size.width
            });
        }

        const json = JSON.stringify(tileNames);
        DataProcessor.downloadData(new TextEncoder().encode(json), `SPRITESHEET_DATA.dat`);
        props.onClose();
    }

    const onImportSpritesheetData = (e: SyntheticEvent) => {
        const files = (e.target as any).files;
        if(files.length !== 1) {
            return;
        }
        const file = files[0];
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const data = new Uint8Array(reader.result as ArrayBuffer);
            console.log(new TextDecoder().decode(data));
        });
        reader.readAsArrayBuffer(file);
    }

    return (
        <Dialog className='bp3-dark' title='Import Spritesheet' isOpen={props.isOpen} onClose={props.onClose}>
            <Stack spacing={4} m={5}>
                <FileInput text="Upload Spritesheet Image..." onInputChange={onUploadImage} inputProps={{accept: '.jpg,.png,.bmp'}} />
                <HStack>
                    <InputGroup>
                        <InputLeftAddon children="Tiles X" />
                        <NumberInput value={sheetSize.width} onChange={(sv, v) => !isNaN(v) && onChangeSheetSize(v, sheetSize.height)} min={0}>
                            <NumberInputField />
                        </NumberInput>
                    </InputGroup>
                    <InputGroup>
                        <InputLeftAddon children="Tiles Y" />
                        <NumberInput value={sheetSize.height} onChange={(sv, v) => !isNaN(v) && onChangeSheetSize(sheetSize.width, v)}>
                            <NumberInputField />
                        </NumberInput>
                    </InputGroup>
                </HStack>
                {rawImageData && <>
                    <HStack>
                        <Image src={rawImageData.dataUri} objectFit='contain' height='100px' />
                        <Tag>Width: {rawImageData.size.width}</Tag>
                        <Tag>Height: {rawImageData.size.height}</Tag>
                    </HStack>
                </>}
                <VStack>
                    {tileData.map((r, i) => (
                        <TileItem key={i} tileData={r} index={i} tileName={tileNames[i] ?? ''} updateName={onUpdateName} />
                    ))}
                </VStack>
                <FileInput text="Upload DAT file..." onInputChange={onImportSpritesheetData} inputProps={{accept: '.dat'}} />
                <Button onClick={onImportAsset} disabled={!rawImageData}>
                    Import Assets
                </Button>
            </Stack>
        </Dialog>
    );
}

interface TileItemProps {
    index: number;
    tileName: number;
    tileData: ImportedImageData;
    updateName: (tileId: number, name: string) => void;
}

const TileItem = (props: TileItemProps) => {
    return (
        <HStack>
            <Input placeholder='Name' value={props.tileName} onChange={(e) => {props.updateName(props.index, e.target.value)}} />
            <Image src={props.tileData.dataUri} objectFit='contain' height='100px' />
        </HStack>
    )
}

export default ImportSpritesheetDialog;