import { Menu, MenuItem } from "@blueprintjs/core";
import { ContextMenu2 } from "@blueprintjs/popover2";
import { Flex } from "@chakra-ui/react";
import { Image } from "@chakra-ui/image";
import { useState } from "react";
import { useAssetContext } from "../../../contexts/AssetContext";
import NewAssetDialog from "./NewAssetDialog";
import { EditorSelectionType, useEditorContext } from "../../../contexts/EditorContext";
import ImportSpritesheetDialog from "./ImportSpritesheetDialog";

const AssetList = () => {
    const [importSpritesheetDialog, setImportSpritesheetDialog] = useState(false);
    const [newAssetDialog, setNewAssetDialog] = useState(false);
    const {currentSelection, setSelection} = useEditorContext();
    const {assets} = useAssetContext();

    const onAssetClicked = (assetId: number) => {
        setSelection(EditorSelectionType.ASSET, assetId);
    }

    return (
        <ContextMenu2 style={{height: '100%'}} content={<Menu>
                <MenuItem icon="plus" text="New Asset..." onClick={() => setNewAssetDialog(true)} />
                <MenuItem icon="plus" text="Import Spritesheet..." onClick={() => setNewAssetDialog(true)} />
            </Menu>}>
            <Flex flexDir='column' overflow='auto' sx={{display: 'flex', flexDirection: 'column'}} height='100%' flex={1}>
                <Menu>
                    {assets.map((asset, i) => (
                       <MenuItem key={i} active={currentSelection?.type === EditorSelectionType.ASSET && i === currentSelection.id} text={asset.key} onClick={() => onAssetClicked(i)} labelElement={<Image src={asset.imagePreviewUri} objectFit='contain' sx={{maxH: '10'}} />}  />
                    ))}
                    <MenuItem icon='plus' text='New Asset...' onClick={() => setNewAssetDialog(true)}/>
                    <MenuItem icon='plus' text='Import Spritesheet...' onClick={() => setImportSpritesheetDialog(true)}/>
                </Menu>

                {newAssetDialog && <NewAssetDialog isOpen={newAssetDialog} onClose={() => setNewAssetDialog(false)} />}

                {importSpritesheetDialog && <ImportSpritesheetDialog isOpen={importSpritesheetDialog} onClose={() => setImportSpritesheetDialog(false)} />}
            </Flex>
        </ContextMenu2>
  );
}

export default AssetList;
