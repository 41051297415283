import { AssetDef, LayerDef, SizeDef } from "../types";
import DataProcessor from "./DataProcessor";

function decToHex(value: number) {
    if (value > 255) {
      return 'FF';
    } else if (value < 0) {
      return '00';
    } else {
      return value.toString(16).padStart(2, '0').toUpperCase();
    }
}
function rgbaToHex(r: number, g: number, b: number, a: number) {
    return '#' + decToHex(r) + decToHex(g) + decToHex(b) + decToHex(a);
}

const getUriFromRgbaData = (rgbaData: Uint32Array, size: SizeDef) => {
    const canvas = document.createElement('canvas',);
    const ctx = canvas.getContext('2d');
    canvas.width = size.width;
    canvas.height = size.height;
    if(ctx) {
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const buf = new Uint8Array(rgbaData.buffer);
        imageData.data.set(buf);
        ctx.putImageData(imageData, 0, 0);
    }
    const dataUrl = canvas.toDataURL();
    return dataUrl;
}

const getImageRgbaData = (img: HTMLImageElement) => {
    let rgbaData: ImageData | null = null;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    if(ctx) {
        ctx.drawImage(img, 0, 0);
    
        rgbaData = ctx.getImageData(0, 0, img.width, img.height);
    }
    //document.removeChild(canvas);
    return rgbaData;
}

export const getRawDataFromRgb = (rgbaData: Uint32Array, initialPalette: string[]) => {
    const rgbaBytes = new Uint8ClampedArray(rgbaData.buffer);
    const palette: string[] = initialPalette;
    const data: number[] = [];
    for(let i = 0; i < rgbaBytes.length; i+=4) {
        const hex = rgbaToHex(rgbaBytes[i], rgbaBytes[i+1], rgbaBytes[i+2], rgbaBytes[i+3]);
        if(!palette.includes(hex)) {
            palette.push(hex);
        }
        data.push(palette.indexOf(hex));
    }
    return data;
}

//Process Image handles consuming a png/jpg/bmp file user specifies, and retrieves size, datauri, and packed data buffer for internal engine use.
export const ProcessImage = (file: File, processedCallback: (data: AssetDef) => void) => {
    getRgbaDataFromImage(file, (data) => {
        const rawData = getRawDataFromRgb(data.imageRgbaData, []);
        const packedData = DataProcessor.getPackedDataFromRawData(rawData);
        processedCallback({
            imagePreviewUri: data.dataUri,
            width: data.size.width,
            height: data.size.height,
            data: packedData.data,
            dataFormat: packedData.format,
            key: file.name
        });
    });
} 

export const ProcessSpritesheet = (data: ImportedImageData, tileCount: SizeDef) => {
    const tiles: ImportedImageData[] = [];
    const totalTileCount = tileCount.width * tileCount.height;
    const tileSize = {
        width: Math.floor(data.size.width / tileCount.width), 
        height: Math.floor(data.size.height / tileCount.height)
    };

    for(let tileIndex = 0; tileIndex < totalTileCount; tileIndex++) {
        let bufferAddr = (Math.trunc(tileIndex / tileCount.width) * (data.size.width * tileSize.height))
            + ((tileIndex % tileCount.width) * tileSize.width);
        const tileImageData: number[] = [];
        for(let y = 0; y < tileSize.height; y++) {
            for(let x = 0; x < tileSize.width; x++) {
                tileImageData.push(data.imageRgbaData[bufferAddr + x]);
            }
            bufferAddr += data.size.width;
        }

        const dataBuf = new Uint32Array(tileImageData);
        tiles.push({
            imageRgbaData: dataBuf,
            size: tileSize,
            dataUri: getUriFromRgbaData(dataBuf, tileSize)
        });
    }

    return tiles;
}


//Process an image representing a layer, consuming a png/jpg/bmp file user specifies, and retrieves layer size and packed data buffer for internal engine use.
export const ProcessLayerImage = (file: File, processedCallback: (data: LayerDef) => void) => {
    getRgbaDataFromImage(file, (data) => {
        const packedData = getRawDataFromRgb(data.imageRgbaData, ['#FFFFFF']);
        processedCallback({
            assetMap: packedData,
            key: file.name,
            tag: ''
        });
    });
}

export interface ImportedImageData {
    size: SizeDef;
    imageRgbaData: Uint32Array;
    dataUri: string;
}

export const getRgbaDataFromImage = (file: File, processedCallback: (data: ImportedImageData) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
        const dataUri = reader.result as string;
        const img = new Image();
        img.addEventListener('load', () => {
            const imageRgbaData = getImageRgbaData(img);
            if(!imageRgbaData) { return; }

            processedCallback({
                size: {
                    width: img.width,
                    height: img.height
                },
                imageRgbaData: new Uint32Array(imageRgbaData.data.buffer),
                dataUri
            });
        });
        img.src = dataUri;
    });
    reader.readAsDataURL(file);
}