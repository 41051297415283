import { Box } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { useAssetContext } from '../../../contexts/AssetContext';
import { EditorSelectionType, EditorSubSelectionType, useEditorContext } from '../../../contexts/EditorContext';
import { useLevelContext } from '../../../contexts/LevelContext';
import useCanvas from '../../../hooks/useCanvas';
import { EmptyLayerTypeDef, LayerDef, LayerTypeDef, SizeDef } from '../../../types';

import './LevelEditor.css';

interface AssetImageData {
    key: string;
    image: HTMLImageElement;
    id: number;
};

const LevelEditor = () => {
    const {currentSelection, selectedLayerSlot} = useEditorContext();
    const {assets} = useAssetContext();
    const {levels, layers, getLayerByKey, updateLayer, getLayerTypeByTag} = useLevelContext();

    const activeLevel = currentSelection.type === EditorSelectionType.LEVEL ? levels[currentSelection.id] : undefined;
    const activeLayer = currentSelection.subType === EditorSubSelectionType.LAYER ? layers[currentSelection.subId] : undefined;

    const groundLayerType = getLayerTypeByTag('ground') ?? EmptyLayerTypeDef;
    const obstacleLayerType = getLayerTypeByTag('obstacle') ?? EmptyLayerTypeDef;
    const activeLayerType = getLayerTypeByTag(activeLayer?.tag ?? '') ?? EmptyLayerTypeDef;

    const groundLayerOffset = groundLayerType.layerOffset;
    const obstacleLayerOffset = obstacleLayerType.layerOffset;

    //world size is WxH of largest layer relevant (cur layer or levels layers)
    let worldSize = {width: 0, height: 0};
    let activeLayerSize = {width: 0, height: 0};
    let activePixelSize = {width: 0, height: 0};
    let activeLayerOffset = {width: 0, height: 0};
    //If we have level selected worldsize is largest layer in level.
    if(activeLevel) {
        const groundLayer = getLayerByKey(activeLevel.groundLayerKey);
        if(groundLayer) {
            worldSize.width = Math.max(worldSize.width, (groundLayerType.gridSize.width * groundLayerType.pixelSize.width) + groundLayerOffset.width);
            worldSize.height = Math.max(worldSize.height, (groundLayerType.gridSize.height * groundLayerType.pixelSize.height) + groundLayerOffset.height);

            if(activeLayer && activeLayer.key === groundLayer.key) {
                activeLayerOffset = groundLayerOffset;
            }
        }
        const obstacleLayer = getLayerByKey(activeLevel.obstacleLayerKey);
        if(obstacleLayer) {
            worldSize.width = Math.max(worldSize.width, (obstacleLayerType.gridSize.width * obstacleLayerType.pixelSize.width) + obstacleLayerOffset.width);
            worldSize.height = Math.max(worldSize.height, (obstacleLayerType.gridSize.height * obstacleLayerType.pixelSize.height) + obstacleLayerOffset.height);

            if(activeLayer && activeLayer.key === obstacleLayer.key) {
                activeLayerOffset = obstacleLayerOffset;
            }
        }
    }
    console.log(activeLayer);
    if(activeLayer) {

        worldSize.width = Math.max(worldSize.width, activeLayerType.gridSize.width * activeLayerType.pixelSize.width);
        worldSize.height = Math.max(worldSize.height, activeLayerType.gridSize.height * activeLayerType.pixelSize.height);
        activeLayerSize = activeLayerType.gridSize;
        activePixelSize = activeLayerType.pixelSize;
        activeLayerOffset = activeLayerType.layerOffset;
    }

    const zoomLevel = useRef(5);
    const offset = useRef({x: 0, y: 0});
    const mouseDown = useRef(false);
    const mouseDragged = useRef(false);
    const mousePos = useRef({x: 0, y: 0});

    const assetSpriteData = useRef<Record<number, AssetImageData>>({});

    const MAX_ZOOM = 10;
    const MIN_ZOOM = 2;
    const ZOOM_STEP = 0.1;

    const EngineCanvasSize = {width: 128, height: 128};

    const getCanvasSize = () => {
        return {
            width: canvasRef.current.getBoundingClientRect().width,
            height: canvasRef.current.getBoundingClientRect().height
        };
    }

    const getRelativeLevelPos = (x: number, y: number, pixelSize: SizeDef, layerOffset: SizeDef) => {
        return {
            x: Math.floor(x * pixelSize.width + offset.current.x + layerOffset.width) * zoomLevel.current,
            y: Math.floor(y * pixelSize.height + offset.current.y + layerOffset.height) * zoomLevel.current,
        };
    }

    const getCanvasToLayerGridPos = (x: number, y: number) => {
        if(!activeLayer) {
            return {x: 0, y: 0};
        }
        const gridPos = {
            x: Math.floor((x - ((offset.current.x + activeLayerOffset.width) * zoomLevel.current)) / (activePixelSize.width * zoomLevel.current)),
            y: Math.floor((y - ((offset.current.y + activeLayerOffset.height) * zoomLevel.current)) / (activePixelSize.height * zoomLevel.current))
        };
        return gridPos;
    }

    const canvasPointInLevel = (canvasX: number, canvasY: number) => {
        const gridPos = getCanvasToLayerGridPos(canvasX, canvasY);
        return (
            gridPos.x >= 0 && gridPos.y >= 0 &&
            gridPos.x < activeLayerSize.width && gridPos.y < activeLayerSize.height
        );
    }

    useEffect(() => {
        const tempSpriteData = [];
        
        for(const asset of assets) {
            const spriteImage = new Image();
            spriteImage.src = asset.imagePreviewUri;

            tempSpriteData.push({
                key: asset.key,
                image: spriteImage,
                id: tempSpriteData.length
            });
        }

        assetSpriteData.current = tempSpriteData;
    }, [assets]);

    const draw = (ctx: CanvasRenderingContext2D, frameCount: number) => {
        const canvasSize = getCanvasSize();
        ctx.canvas.width = canvasSize.width;
        ctx.canvas.height = canvasSize.height;
        ctx.imageSmoothingEnabled = false;
        const zoom = zoomLevel.current;

        // const roomDataSizeX = activeLayerSize.width*activePixelSize.width;
        // const roomDataSizeY = activeLayerSize.height*activePixelSize.height;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvasSize.width, canvasSize.height);

        const originX = offset.current.x * zoom;
        const originY = offset.current.y * zoom;

        // //Fill in rect of room bounds
        const levelOffsetX = offset.current.x * zoom;
        const levelOffsetY = offset.current.y * zoom;

        if(activeLevel) {
            ctx.fillStyle = '#F6F6F6';
            ctx.strokeStyle = '#000000'
            ctx.lineWidth = 2 * zoomLevel.current;
            ctx.fillRect(levelOffsetX, levelOffsetY, worldSize.width * zoom, worldSize.height * zoom);

            //draw rect for max room bounds
            ctx.strokeStyle = 'gray';
            ctx.strokeRect(levelOffsetX, levelOffsetY, worldSize.width * zoom, worldSize.height * zoom);
        }

        //Draw rect for engine camera render
        ctx.strokeStyle = '#dd000033';
        ctx.lineWidth = zoomLevel.current;
        ctx.strokeRect(originX, originY, EngineCanvasSize.width * zoom, EngineCanvasSize.height * zoom);

        if(activeLayer) {
            const activeLayerTotalWidth = activeLayerSize.width * activePixelSize.width * zoom;
            const activeLayerTotalHeight = activeLayerSize.height * activePixelSize.height * zoom;

            ctx.fillStyle='#DDD';
            for(let x = 1; x < activeLayerSize.width; x++) {
                const relativePos = getRelativeLevelPos(x, 0, activeLayerType.pixelSize, activeLayerOffset);
                ctx.fillRect(relativePos.x, relativePos.y, zoom, activeLayerTotalHeight);
            }
            for(let y = 1; y < activeLayerSize.height; y++) {
                const relativePos = getRelativeLevelPos(0, y, activeLayerType.pixelSize, activeLayerOffset);
                ctx.fillRect(relativePos.x, relativePos.y - (zoom / 2), activeLayerTotalWidth, zoom);
            }

            const relativePos = getRelativeLevelPos(0, 0, activeLayerType.pixelSize, activeLayerOffset);
            ctx.strokeStyle = 'black';
            ctx.strokeRect(relativePos.x, relativePos.y, activeLayerTotalWidth, activeLayerTotalHeight);
        }

        if(activeLevel) {
            const groundLayer = getLayerByKey(activeLevel.groundLayerKey);
            if(groundLayer && (!activeLayer || activeLayer.key !== groundLayer.key)) {
                drawLayer(groundLayer, groundLayerType, groundLayerOffset, ctx, zoom);
            }
            const obstacleLayer = getLayerByKey(activeLevel.obstacleLayerKey);
            if(obstacleLayer && (!activeLayer || activeLayer.key !== obstacleLayer.key)) {
                drawLayer(obstacleLayer, obstacleLayerType, obstacleLayerOffset, ctx, zoom);
            }
        }

        //Draw each sprite on active layers spritemap
        if(activeLayer) {
            drawLayer(activeLayer, activeLayerType, activeLayerOffset, ctx, zoom);
        }
    };

    const drawLayer = (layer: LayerDef, layerType: LayerTypeDef, offset: SizeDef, ctx: CanvasRenderingContext2D, zoom: number) => {
        for(let i =0; i < layer.assetMap.length; i++) {
            const cellVal = layer.assetMap[i];
            if(cellVal > 0) {
                let assetId = -1; 
                switch(cellVal) {
                    case 1:
                        assetId = layerType.assetSlot1;
                        break;
                    case 2:
                        assetId = layerType.assetSlot2;
                        break;
                    case 3:
                        assetId = layerType.assetSlot3;
                        break;
                }

                if(assetId >= 0) {
                    const asset = assets[assetId];
                    if(asset && assetSpriteData.current[assetId]) {
                        const x = i % layerType.gridSize.width;
                        const y = Math.floor(i / layerType.gridSize.width);
                        const relativePos = getRelativeLevelPos(x, y, layerType.pixelSize, offset);
                        ctx.drawImage(assetSpriteData.current[assetId].image, relativePos.x, relativePos.y, asset.width * zoom, asset.height * zoom);
                    }
                }
            }
        }
    }

    const onMouseDown = (e: any) => {
        let clickX = e.clientX - canvasRef.current.getBoundingClientRect().left;
        let clickY = e.clientY - canvasRef.current.getBoundingClientRect().top;

        if(e.button === 0) {
            mouseDown.current = true;
            mouseDragged.current = false;
            mousePos.current = {x: clickX, y: clickY};
        } else if(e.button === 2) {
            //Right Mouse Button
            if(canvasPointInLevel(clickX, clickY) && activeLayer && currentSelection && currentSelection.subType === EditorSubSelectionType.LAYER) {
                const gridPos = getCanvasToLayerGridPos(clickX, clickY);
                const indexToUpdate = activeLayerSize.width * gridPos.y + gridPos.x;
                if(activeLayer.assetMap.length > indexToUpdate) {
                    activeLayer.assetMap[indexToUpdate] = 0;
                    updateLayer(currentSelection.subId, activeLayer);
                }
            }
        }
    }

    const onMouseUp = (e: any) => {
        mouseDown.current = false;

        if(!mouseDragged.current && e.button === 0 && activeLayer && selectedLayerSlot > 0) {
            let clickX = e.clientX - canvasRef.current.getBoundingClientRect().left;
            let clickY = e.clientY - canvasRef.current.getBoundingClientRect().top;
            if(canvasPointInLevel(clickX, clickY)) {
                const gridPos = getCanvasToLayerGridPos(clickX, clickY);
                const indexToUpdate = activeLayerSize.width * gridPos.y + gridPos.x;
                if(activeLayer.assetMap.length <= indexToUpdate) {
                    activeLayer.assetMap.concat(Array<number>(indexToUpdate - activeLayer.assetMap.length + 1).fill(0))
                }
                activeLayer.assetMap[indexToUpdate] = selectedLayerSlot;
                if(currentSelection.subType === EditorSubSelectionType.LAYER) {
                    updateLayer(currentSelection.subId, activeLayer);
                }
            }
        }

        mouseDragged.current = false;
    }

    const onMouseWheel = (e: any) => {
        if(e.wheelDelta > 0) {
            if(zoomLevel.current < MAX_ZOOM) {
                zoomLevel.current += ZOOM_STEP;
            }
        } else {
            if(zoomLevel.current > MIN_ZOOM) {
                zoomLevel.current -= ZOOM_STEP;
            }
        }
    }

    const onMouseMove = (e: any) => {
        if(mouseDown.current) {
            const delta = [(e.x - mousePos.current.x) / zoomLevel.current, (e.y - mousePos.current.y)/zoomLevel.current];
            offset.current = {x: offset.current.x + delta[0], y: offset.current.y + delta[1]};
            mousePos.current = {x: e.x, y: e.y};

            mouseDragged.current = true;
        }
    }

    const onContextMenu = (e: any) => {
        e.preventDefault();
    }

    const onMouseOut = (e: any) => {
        mouseDown.current = false;
        mouseDragged.current = false;
    }

    const canvasRef = useCanvas(draw, {
        onMouseDown,
        onMouseUp,
        onMouseWheel,
        onMouseMove,
        onContextMenu,
        onMouseOut
    });

    return (
        <Box w='100%' h='100%'>
            <canvas className='levelEditorCanvas' ref={canvasRef}  />
        </Box>
    );
}

export default LevelEditor;
