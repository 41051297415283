export interface SizeDef {
    width: number;
    height: number;
}

export enum DataFormat {
    BPP1,
    BPP2
}

export interface PackedData {
    data: number[];
    format: DataFormat;
}

export type ActorKey = string;
export interface ActorDef {
    key: ActorKey;
    
    animations: AnimationKey[];
}

export type AnimationKey = string;
export interface AnimationDef {
    key: AnimationKey;
    
    frameAssetKeys: AssetKey[];

    frameDuration: number;
    loop: boolean;
}

export type AssetKey = string;

export interface AssetDef {
    key: AssetKey;

    data: number[];
    width: number;
    height: number;

    imagePreviewUri: string;
    dataFormat: DataFormat;
}

export type LayerKey = string;

export interface LayerDef {
    key: LayerKey;
    assetMap: number[];

    tag: string;
}

export interface LevelLayerDef {
    levelOffset: SizeDef;
    layerKey: string;
}

export type LevelKey = string;
export interface LevelDef {
    key: LevelKey;

    groundLayerKey: LayerKey;
    obstacleLayerKey: LayerKey;
}

export const EmptyLayerTypeDef: LayerTypeDef = {
    tag: '',
    layerOffset: {width: 0, height: 0},
    gridSize: {width: 0, height: 0},
    pixelSize: {width: 0, height: 0},
    assetSlot1: -1,
    assetSlot2: -1,
    assetSlot3: -1,
}

export interface LayerTypeDef {
    tag: string;
    layerOffset: SizeDef;
    gridSize: SizeDef;
    pixelSize: SizeDef;
    
    assetSlot1: number;
    assetSlot2: number;
    assetSlot3: number;
}