import React, { useState } from "react";
import { ActorDef, ActorKey, AnimationDef, AnimationKey } from "../types";
import { getInitialStorage } from "../utils/Helpers";

interface ActorContextInterface {
    actors: ActorDef[];
    getActorByKey: (key: ActorKey) => ActorDef | undefined;
    createActor: (actor: ActorDef) => void;
    updateActor: (actorId: number, actor: ActorDef) => void;
    removeActor: (actorId: number) => void;

    animations: AnimationDef[];
    getAnimationByKey: (key: AnimationKey) => AnimationDef | undefined;
    createAnimation: (animation: AnimationDef) => void;
    updateAnimation: (animationId: number, animation: AnimationDef) => void;
    removeAnimation: (animationId: number) => void;
}

const ActorContext = React.createContext<ActorContextInterface>({
    actors: [],
    getActorByKey: ()=>undefined,
    createActor: ()=>{},
    updateActor: ()=>{},
    removeActor: ()=>{},

    animations: [],
    getAnimationByKey: ()=>undefined,
    createAnimation: ()=>{},
    updateAnimation: ()=>{},
    removeAnimation: ()=>{}
});
ActorContext.displayName = 'ActorContext';

export const ActorProvider = (props: any) => {
    const [actors, setActors] = useState<ActorDef[]>(getInitialStorage('actors'));
    const [animations, setAnimations] = useState<AnimationDef[]>(getInitialStorage('animations'));

    React.useEffect(() => {
        localStorage.setItem('actors', JSON.stringify(actors));
    }, [actors]);

    React.useEffect(() => {
        localStorage.setItem('animations', JSON.stringify(animations));
    }, [animations]);

    const value = React.useMemo(() => {
        const getActorByKey = (key: ActorKey) => {
            return actors.find(r => r.key === key);
        }

        const createActor = (actor: ActorDef) => {
            setActors(prevActors => [...prevActors, actor]);
        }

        const updateActor = (actorId: number, actor: ActorDef) => {
            const updatedActors = actors.slice();
            updatedActors[actorId] = actor;
            setActors(updatedActors);
        }

        const removeActor = (actorId: number) => {
            setActors(actors.filter((r, i) => i !== actorId));
        }

        const getAnimationByKey = (key: AnimationKey) => {
            return animations.find(r => r.key === key);
        }

        const createAnimation = (animation: AnimationDef) => {
            setAnimations(prevAnims => [...prevAnims, animation]);
        }

        const updateAnimation = (animId: number, animation: AnimationDef) => {
            const updatedAnimations = animations.slice();
            updatedAnimations[animId] = animation;
            setAnimations(updatedAnimations);
        }

        const removeAnimation = (animId: number) => {
            setAnimations(animations.filter((r, i) => i !== animId));
        }

        return ({ actors, getActorByKey, createActor, updateActor, removeActor,
            animations, getAnimationByKey, createAnimation, updateAnimation, removeAnimation });
    }, [actors, animations]);

    return <ActorContext.Provider value={value} {...props} />
}

export const useActorContext = () => {
    return React.useContext(ActorContext);
}