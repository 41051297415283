import { Box, Flex } from "@chakra-ui/react";
import { EditorSelectionType, EditorSubSelectionType, useEditorContext } from "../../../contexts/EditorContext";
import ActorProperties from "./ActorProperties";
import AnimationProperties from "./AnimationProperties";
import AssetProperties from "./AssetProperties";
import LayerProperties from "./LayerProperties";
import LevelProperties from "./LevelProperties";

const PropertiesPanel = () => {
    const {currentSelection} = useEditorContext();

    let propertiesContent = null;
    switch(currentSelection.type) {
        case EditorSelectionType.ASSET:
            propertiesContent = <AssetProperties selectedAssetId={currentSelection.id} />;
            break;
        case EditorSelectionType.ACTOR:
            propertiesContent = <ActorProperties selectedActorId={currentSelection.id} />;
            break;
        case EditorSelectionType.ANIMATION:
            propertiesContent = <AnimationProperties selectedAnimationId={currentSelection.id} />;
            break;
        case EditorSelectionType.LEVEL:
            //If we have a layer subselected, show layer properties, otherwise level properties
            if(currentSelection.subType === EditorSubSelectionType.LAYER && currentSelection.subId > -1) {
                propertiesContent = <LayerProperties selectedLayerIndex={currentSelection.subId} />;
            } else {
                propertiesContent = <LevelProperties selectedLevelIndex={currentSelection.id} />;
            }
            break;
    }

    return (
        <Flex flexDir='column' sx={{display: 'flex', flexDirection: 'column'}} height='100%' flex={1}>
            <Box overflowY='auto'>
                {propertiesContent}
            </Box>
        </Flex>);
}

export default PropertiesPanel;