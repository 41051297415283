export const getInitialStorage = (storageKey: string) => {
    const assetStr = localStorage.getItem(storageKey);
    if(assetStr) {
        try
        {
            const assets = JSON.parse(assetStr);
            if(Array.isArray(assets)) {
                return assets;
            }
        } catch(e) {
            console.error(e);
        }
    }

    return [];
};

export const getInitialStorageObject = (storageKey: string) => {
    const assetStr = localStorage.getItem(storageKey);
    if(assetStr) {
        return JSON.parse(assetStr);
    }

    return undefined;
};