import { Button, Input, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useActorContext } from "../../../contexts/ActorContext";
import { AnimationKey } from "../../../types";
import ActorAnimationList from "../Actors/ActorAnimationList";

interface ActorPropertiesProps {
    selectedActorId: number;
}

const ActorProperties = (props: ActorPropertiesProps) => {
    //We use temp key to store the user edited key. If non-unique will not commit until made unique
    const [tempKey, setTempKey] = useState('');

    const {actors, getActorByKey, updateActor, removeActor} = useActorContext();

    const selectedActor = actors[props.selectedActorId];

    useEffect(() => {
        if(selectedActor) {
            setTempKey(selectedActor.key);
        } else {
            setTempKey('');
        }
    }, [selectedActor]);

    if(!selectedActor) {
        return (<Text>Actor not found ActorID: {props.selectedActorId}</Text>);
    }

    const updateKey = (val: string) => {
        setTempKey(val);
        if(getActorByKey(val) !== undefined) {return;}
        let updatedActor = selectedActor;
        updatedActor.key = val;
        updateActor(props.selectedActorId, updatedActor);
    }

    const updateActorAnims = (animationKeys: AnimationKey[]) => {
        let updatedActor = selectedActor;
        updatedActor.animations = animationKeys;
        updateActor(props.selectedActorId, updatedActor);
    }

    return (
    <Stack m={4}  spacing={4}>
        <Input placeholder='Key' value={tempKey} onChange={(e) => {updateKey(e.target.value)}}
            isInvalid={getActorByKey(tempKey) !== undefined && tempKey !== selectedActor.key} />
        <ActorAnimationList actor={selectedActor} updateActor={updateActorAnims} />
        <Button colorScheme='red' onClick={() => removeActor(props.selectedActorId)}>Delete Actor</Button>
    </Stack>);
}

export default ActorProperties;