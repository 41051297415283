import React, { useState } from "react";
import { AssetDef, AssetKey } from "../types";
import DataProcessor from "../utils/DataProcessor";
import { getInitialStorage } from "../utils/Helpers";

interface AssetContextInterface {
    assets: AssetDef[];
    getAssetByKey: (key: AssetKey) => AssetDef | undefined;
    getAssetIndexByKey: (key: AssetKey) => number;
    assetKeyAvailable: (key: AssetKey) => boolean;
    importAsset: (asset: AssetDef) => void;

    updateAsset: (assetId: number, asset: AssetDef) => void;

    removeAsset: (assetId: number) => void;

    exportAsset: (assetId: number) => void;
}

const AssetContext = React.createContext<AssetContextInterface>({
    assets: [],
    getAssetByKey: () => undefined,
    getAssetIndexByKey: () => -1,
    assetKeyAvailable: () => false,
    importAsset: ()=>{},
    updateAsset: ()=>{},
    removeAsset: ()=>{},
    exportAsset: ()=>{},
});
AssetContext.displayName = 'AssetContext';

export const AssetProvider = (props: any) => {
    const [assets, setAssets] = useState<AssetDef[]>(getInitialStorage('assets'));

    React.useEffect(() => {
        localStorage.setItem('assets', JSON.stringify(assets));
    }, [assets]);

    const value = React.useMemo(() => {
        const getAssetByKey = (key: AssetKey) => {
            return assets.find(r => r.key === key);
        }
        const getAssetIndexByKey = (key: AssetKey) => {
            return assets.findIndex(r => r.key === key);
        }
        const importAsset = (asset: AssetDef) => {
            setAssets(prevAssets => [...prevAssets, asset]);
        }
        const assetKeyAvailable = (assetKey: AssetKey) => {
            return assets.filter(r => r.key === assetKey).length === 0;
        }

        const updateAsset = (assetId: number, asset: AssetDef) => {
            const updatedAssets = assets.slice();
            updatedAssets[assetId] = asset;
            setAssets(updatedAssets);
        }

        const removeAsset = (assetId: number) => {
            setAssets(assets.filter((r, i) => i !== assetId));
        }
        
        const exportAsset = (assetId: number) => {
            const asset = assets[assetId];
            console.log(asset);
            console.log(asset.data);
            DataProcessor.downloadData(new Uint8Array(asset.data), `ASSET_${asset.key}.dat`);
        }

        return ({assets, getAssetByKey, getAssetIndexByKey, importAsset, updateAsset, removeAsset, exportAsset, assetKeyAvailable})
    }, [assets]);

    return <AssetContext.Provider value={value} {...props} />
}

export const useAssetContext = () => {
    return React.useContext(AssetContext);
}