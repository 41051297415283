import { useEffect, useRef } from "react"

export interface CanvasEventListeners {
    onMouseDown?: (e: any) => void;
    onMouseUp?: (e: any) => void;
    onMouseMove?: (e: any) => void;
    onMouseWheel?: (e: any) => void;
    onContextMenu?: (e: any) => void;
    onMouseOut?: (e: any) => void;
}

const useCanvas = (draw: (ctx: CanvasRenderingContext2D, frameCount: number) => void, listeners: CanvasEventListeners) => {
  
    const canvasRef = useRef<any>(null)
    
    useEffect(() => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      context.imageSmoothingEnabled = false;
      let frameCount = 0;
      let animationFrameId = 0;
      
      const render = () => {
        frameCount++
        draw(context, frameCount)
        animationFrameId = window.requestAnimationFrame(render)
      }
      render()
      if(listeners.onMouseDown) {
        canvas.addEventListener('mousedown', listeners.onMouseDown);
      }
      if(listeners.onMouseUp) {
        canvas.addEventListener('mouseup', listeners.onMouseUp);
      }
      if(listeners.onMouseMove) {
        canvas.addEventListener('mousemove', listeners.onMouseMove);
      }
      if(listeners.onMouseWheel) {
        canvas.addEventListener('mousewheel', listeners.onMouseWheel);
      }
      if(listeners.onContextMenu) {
        canvas.addEventListener('contextmenu', listeners.onContextMenu);
      }
      if(listeners.onMouseOut) {
        canvas.addEventListener('mouseout', listeners.onMouseOut);
      }
      
      return () => {
        window.cancelAnimationFrame(animationFrameId);
        canvas.removeEventListener('mousedown', listeners.onMouseDown);
        canvas.removeEventListener('mouseup', listeners.onMouseUp);
        canvas.removeEventListener('mousemove', listeners.onMouseMove);
        canvas.removeEventListener('mousewheel', listeners.onMouseWheel);
        canvas.removeEventListener('contextmenu', listeners.onContextMenu);
      }
    }, [draw, listeners]);
    
    return canvasRef;
  }
  
  export default useCanvas;