import { useEffect, useRef } from 'react';
import { useAssetContext } from '../../../contexts/AssetContext';
import { useEditorContext } from '../../../contexts/EditorContext';
import useCanvas from '../../../hooks/useCanvas';

const AssetPreviewPanel = () => {
    const {currentSelection} = useEditorContext();
    const {assets} = useAssetContext();

    const selectedAsset = currentSelection && assets[currentSelection.id];

    const frameAssetImage = useRef<HTMLImageElement>();

    useEffect(() => {
        if(selectedAsset) {
            const assetImg = new Image();
            assetImg.src = selectedAsset.imagePreviewUri;

            frameAssetImage.current = assetImg;
        }
    }, [selectedAsset])

    const draw = (ctx: CanvasRenderingContext2D, frameCount: number) => {
        if(!selectedAsset) {
            return;
        }
        ctx.canvas.width = selectedAsset.width;
        ctx.canvas.height = selectedAsset.height;

        if(frameAssetImage.current) {
            ctx.drawImage(frameAssetImage.current, 0, 0, selectedAsset.width, selectedAsset.height)
        }
    };

    const canvasRef = useCanvas(draw, {});

    return (
        <canvas className='assetPreviewCanvas' ref={canvasRef} style={{height:'100%', maxHeight:'100%', maxWidth:'100%'}} />
    );
}

export default AssetPreviewPanel;
