import React, { useState } from "react";
import { AnimationKey } from "../types";
import { getInitialStorageObject } from "../utils/Helpers";

export enum EditorSelectionType {
    NONE,
    ASSET,
    ACTOR,
    LEVEL,
    ANIMATION
};

export enum EditorSubSelectionType {
    NONE,
    LAYER
}

export interface EditorSelection {
    type: EditorSelectionType;
    id: number;

    subType: EditorSubSelectionType;
    subId: number;
}

const EmptySelection = {type: EditorSelectionType.NONE, id: -1, subType: EditorSubSelectionType.NONE, subId: -1};

interface EditorContextInterface {
    currentSelection: EditorSelection;
    setSelection: (newSelectionType: EditorSelectionType, newSelectionId?: number, newSubSelectionType?: EditorSubSelectionType, newSubId?: number) => void;
    
    selectedLayerSlot: number;
    setSelectedLayerSlot: (slot: number) => void;

    actorAnimationPreviewSelection: AnimationKey;
    setActorAnimationPreviewSelection: (selection: AnimationKey) => void;
}

const EditorContext = React.createContext<EditorContextInterface>({
    currentSelection: EmptySelection,
    setSelection: () => {},

    selectedLayerSlot: -1,
    setSelectedLayerSlot: ()=>{},

    actorAnimationPreviewSelection: '',
    setActorAnimationPreviewSelection: ()=>{},
});
EditorContext.displayName = 'EditorContext';

export const EditorProvider = (props: any) => {
    const [currentSelection, setCurrentSelection] = useState<EditorSelection>(EmptySelection);
    const [actorAnimationPreviewSelection, setActorAnimationPreviewSelection] = useState<EditorSelection | undefined>(undefined);

    const [selectedLayerSlot, setSelectedLayerSlot] = useState(-1);

    const [groundLayerOffset, setGroundLayerOffset] = useState(getInitialStorageObject('editor_settings')?.groundLayerOffset ?? {width: 0, height: 0});
    const [obstacleLayerOffset, setObstacleLayerOffset] = useState(getInitialStorageObject('editor_settings')?.obstacleLayerOffset ?? {width: 0, height: 0});

    React.useEffect(() => {
        localStorage.setItem('editor_settings', JSON.stringify({
            groundLayerOffset,
            obstacleLayerOffset
        }));
    }, [groundLayerOffset, obstacleLayerOffset]);

    const updateSelection = (newSelectionType: EditorSelectionType, newSelectionId = -1, newSubSelectionType = EditorSubSelectionType.NONE, newSubId = -1) => {
        setSelectedLayerSlot(-1);
        setCurrentSelection({
            type: newSelectionType,
            id: newSelectionId,
            subType: newSubSelectionType,
            subId: newSubId
        });
    }

    const value = React.useMemo(() => {
        return ({ currentSelection, setSelection: updateSelection, 
            actorAnimationPreviewSelection, setActorAnimationPreviewSelection,
            selectedLayerSlot, setSelectedLayerSlot,
            groundLayerOffset, setGroundLayerOffset, obstacleLayerOffset, setObstacleLayerOffset})
    }, [currentSelection, selectedLayerSlot, actorAnimationPreviewSelection,groundLayerOffset, obstacleLayerOffset]);

    return <EditorContext.Provider value={value} {...props} />
}

export const useEditorContext = () => {
    return React.useContext(EditorContext);
}