import { Tab, Tabs } from "@blueprintjs/core";
import { Box } from "@chakra-ui/layout";
import ActorList from "../Actors/ActorList";
import AnimationList from "../Actors/AnimationList";
import AssetList from "./AssetList";

const AssetPanel = () => {
    return (
        <Box height='100%'>
        <Tabs className='fullheight' renderActiveTabPanelOnly={true}>
            <Tab id="assets" title='Assets' panel={<AssetList />} panelClassName='fullheight' />
            <Tab id="actors" title='Actors' panel={<ActorList />} panelClassName='fullheight' />
            <Tab id="animations" title='Animations' panel={<AnimationList />} panelClassName='fullheight' />
        </Tabs>
        </Box>
    )
}

export default AssetPanel;