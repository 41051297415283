import { Dialog } from "@blueprintjs/core";
import { Button, Input, Stack } from "@chakra-ui/react";
import { useState } from "react";
import { useActorContext } from "../../../contexts/ActorContext";

interface NewActorDialogProps {
    isOpen: boolean;
    onClose: () => void
}

const NewActorDialog = (props: NewActorDialogProps) => {
    const [actorKey, setActorKey] = useState('');

    const {getActorByKey, createActor} = useActorContext();

    const keyCollides = getActorByKey(actorKey) !== undefined;

    const onCreateActor = () => {
        createActor({
            key: actorKey,
            animations: []
        });
        props.onClose();
    }

    return (
        <Dialog className='bp3-dark' title='Create Actor' isOpen={props.isOpen} onClose={props.onClose}>
            <Stack spacing={4} m={5}>
                <Input placeholder='Name' value={actorKey} onChange={(e) => {setActorKey(e.target.value)}} isInvalid={keyCollides} />
                <Button onClick={onCreateActor} disabled={!actorKey || keyCollides}>
                    Create Actor
                </Button>
            </Stack>
        </Dialog>
    );
}

export default NewActorDialog;