import { Button } from "@blueprintjs/core";
import { GridItem, HStack, Text, Image, Grid } from "@chakra-ui/react";
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, defaultAnimateLayoutChanges, rectSortingStrategy, SortableContext, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { useState } from "react";
import { useAssetContext } from "../../../contexts/AssetContext";
import { AnimationDef, AssetDef, AssetKey } from "../../../types";
import SelectAssetDialog from "../Assets/SelectAssetDialog";

interface AnimationFrameListProps {
    animation: AnimationDef;
    updateAnimation: (newFrames: AssetKey[]) => void;
}

const AnimationFrameList = (props: AnimationFrameListProps) => {
    const [addFrameModal, setAddFrameModal] = useState(false);
    const frameIds = props.animation.frameAssetKeys?.map((r, i) => i.toString());
    const {getAssetByKey} = useAssetContext();

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const handleDragEnd = (e: DragEndEvent) => {
        const {active, over} = e;
        if(over && active.id !== over.id) {
            props.updateAnimation(arrayMove(props.animation.frameAssetKeys, parseInt(active.id), parseInt(over.id)));
        }
    }

    const onAddFrame = () => {
        setAddFrameModal(true);
    }

    const onDeleteFrame = (frameId: string) => {
        props.updateAnimation(props.animation.frameAssetKeys.filter((r, i) => i !== frameIds.indexOf(frameId)));
    }

    const addFrameFromAsset = (assetKeys: AssetKey[]) => {
        props.updateAnimation([...props.animation.frameAssetKeys ?? [], ...assetKeys]);
        setAddFrameModal(false);
    }

    return (
    <>
    <HStack>
        <Text fontSize="md">Frames</Text>
        <Button icon="add" onClick={onAddFrame} />
    </HStack>
    <Grid templateColumns="repeat(3, 1fr)" gap={2}>
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={frameIds ?? []} strategy={rectSortingStrategy}>
            {frameIds?.map(id => <AnimationFrameListItem key={id} id={id} onRemove={() => onDeleteFrame(id)} frameAsset={getAssetByKey(props.animation.frameAssetKeys[parseInt(id)])} />)}
        </SortableContext>
    </DndContext>
    </Grid>
    {addFrameModal && <SelectAssetDialog isOpen={addFrameModal} onClose={() => setAddFrameModal(false)} onAssetsSelected={addFrameFromAsset} />}
    </>
    );
}

interface AnimationFrameListItemProps {
    id: string;
    onRemove: () => void;
    frameAsset?: AssetDef;
} 

const AnimationFrameListItem = (props: AnimationFrameListItemProps) => {
    const {attributes, listeners,
        setNodeRef, transform, transition, 
    } = useSortable({id: props.id, animateLayoutChanges: defaultAnimateLayoutChanges})

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };
    
    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <GridItem display='flex' pos='relative'>
                <Button onClick={() => props.onRemove()} icon='remove' style={{position: 'absolute', top: 0, right: 0, opacity: 0.5}} />
                <Image m='auto' w='100%' maxH='200px' maxW='200px' src={props.frameAsset && props.frameAsset.imagePreviewUri} objectFit='cover' />
            </GridItem>
        </div>
    );
}

export default AnimationFrameList;