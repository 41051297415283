import { Dialog, FileInput } from "@blueprintjs/core";
import { Button, Input, Stack } from "@chakra-ui/react";
import { SyntheticEvent, useState } from "react";
import { useLevelContext } from "../../../contexts/LevelContext";
import DataProcessor from "../../../utils/DataProcessor";
import { ProcessLayerImage } from "../../../utils/ImageProcessor";

interface NewLayerDialogProps {
    isOpen: boolean;
    onClose: () => void;
    tag: string;
}

const NewLayerDialog = (props: NewLayerDialogProps) => {
    const [layerKey, setLayerKey] = useState('');
    const [assetMap, setAssetMap] = useState<number[]>([]);

    const {createLayer} = useLevelContext();

    const onCreateLayer = () => {
        createLayer({
            key: layerKey,
            assetMap: assetMap,
            tag: props.tag
        });
        props.onClose();
    }

    const onUploadLayer = (e: SyntheticEvent) => {
        const files = (e.target as any).files;
        if(files.length !== 1) {
            return;
        }
        const file = files[0];
        ProcessLayerImage(file, (data) => {
            if(layerKey === '') {
                setLayerKey(data.key);
            }
            setAssetMap(data.assetMap);
        });
    }

    const onUploadLayerData = (e: SyntheticEvent) => {
        const files = (e.target as any).files;
        if(files.length !== 1) {
            return;
        }
        const file = files[0];
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const data = new Uint8Array(reader.result as ArrayBuffer);
            const unpackedData = DataProcessor.importLayer(data);

            setAssetMap(unpackedData.assetMap);
        });
        reader.readAsArrayBuffer(file);
    }

    return (<Dialog className='bp3-dark' title='New Layer' isOpen={props.isOpen} onClose={props.onClose}>
    <Stack spacing={4} m={5}>
        <Input placeholder='Name' value={layerKey} onChange={(e) => {setLayerKey(e.target.value)}} />
        <FileInput text="Import Layer Data..." onInputChange={onUploadLayerData} inputProps={{accept: '.dat'}} />
        <FileInput text="Import Layer Image..." onInputChange={onUploadLayer} inputProps={{accept: '.jpg,.png,.bmp'}} />
        <Button onClick={onCreateLayer} disabled={layerKey === ''}>
            Create Layer
        </Button>
    </Stack>
</Dialog>);
}

export default NewLayerDialog;