import { Button, Checkbox, Input, InputGroup, InputLeftAddon, NumberInput, NumberInputField, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useActorContext } from "../../../contexts/ActorContext";
import { AssetKey } from "../../../types";
import AnimationFrameList from "../Actors/AnimationFrameList";

interface AnimationPropertiesProps {
    selectedAnimationId: number;
}

const AnimationProperties = (props: AnimationPropertiesProps) => {
    //We use temp key to store the user edited key. If non-unique will not commit until made unique
    const [tempKey, setTempKey] = useState('');

    const {animations, getAnimationByKey, updateAnimation, removeAnimation} = useActorContext();

    const selectedAnimation = animations[props.selectedAnimationId];

    useEffect(() => {
        if(selectedAnimation) {
            setTempKey(selectedAnimation.key);
        } else {
            setTempKey('');
        }
    }, [selectedAnimation]);

    if(!selectedAnimation) {
        return (<Text>Animation not found AnimationID: {props.selectedAnimationId}</Text>);
    }

    const updateKey = (val: string) => {
        setTempKey(val);
        if(getAnimationByKey(val) !== undefined) {return;}

        let updatedAnimation = selectedAnimation;
        updatedAnimation.key = val;
        updateAnimation(props.selectedAnimationId, updatedAnimation);
    }

    const updateFrameDuration = (val: number) => {
        let updatedAnimation = selectedAnimation;
        updatedAnimation.frameDuration = val;
        updateAnimation(props.selectedAnimationId, updatedAnimation);
    }

    const updateLoop = (val: boolean) => {
        let updatedAnimation = selectedAnimation;
        updatedAnimation.loop = val;
        updateAnimation(props.selectedAnimationId, updatedAnimation);
    }

    const updateFrames = (newFrameAssetKeys: AssetKey[]) => {
        let updatedAnimation = selectedAnimation;
        updatedAnimation.frameAssetKeys = newFrameAssetKeys;
        updateAnimation(props.selectedAnimationId, updatedAnimation);
    }

    return (
    <Stack m={4}  spacing={4}>
        <Input placeholder='Name' value={tempKey} onChange={(e) => {updateKey(e.target.value)}} 
            isInvalid={getAnimationByKey(tempKey) !== undefined && tempKey !== selectedAnimation.key} />
        <Stack spacing={4} direction='row'>
            <InputGroup>
                <InputLeftAddon children="Time" />
                <NumberInput value={selectedAnimation.frameDuration} onChange={(sv, v) => updateFrameDuration(v)}>
                    <NumberInputField />
                </NumberInput>
            </InputGroup>
            <Checkbox isChecked={selectedAnimation.loop} onChange={(e) => updateLoop(e.target.checked)}>Loop</Checkbox>
        </Stack>
        <AnimationFrameList animation={selectedAnimation} updateAnimation={updateFrames} />
        <Button colorScheme='red' onClick={() => removeAnimation(props.selectedAnimationId)}>Delete Animation</Button>
    </Stack>);
}

export default AnimationProperties;