import { Dialog } from "@blueprintjs/core";
import { Stack } from "@chakra-ui/layout";
import { Button, Input } from "@chakra-ui/react";
import { useState } from "react";
import { useActorContext } from "../../../contexts/ActorContext";

interface NewAnimationDialogProps {
    isOpen: boolean;
    onClose: () => void
}

const NewAnimationDialog = (props: NewAnimationDialogProps) => {
    const [key, setKey] = useState('');

    const {getAnimationByKey, createAnimation} = useActorContext();

    const keyCollides = getAnimationByKey(key) !== undefined;

    const onCreateAnim = () => {
        createAnimation({
            key: key,
            frameDuration: 0.25,
            frameAssetKeys: [],
            loop: true
        });
        props.onClose();
    }

    return (
        <Dialog className='bp3-dark' title='Create Animation' isOpen={props.isOpen} onClose={props.onClose}>
            <Stack spacing={4} m={5}>
                <Input placeholder='Name' value={key} onChange={(e) => {setKey(e.target.value)}} />
                <Button onClick={onCreateAnim} disabled={!key || keyCollides}>
                    Create Animation
                </Button>
            </Stack>
        </Dialog>
    );
}

export default NewAnimationDialog;