import { Menu, MenuItem } from "@blueprintjs/core";
import { ContextMenu2 } from "@blueprintjs/popover2";
import { Flex } from "@chakra-ui/layout";
import { useState } from "react";
import { EditorSelectionType, useEditorContext } from "../../../contexts/EditorContext";
import { useLevelContext } from "../../../contexts/LevelContext";
import NewLevelDialog from "./NewLevelDialog";

const LevelsList = () => {
    const [newLevelDialog, setNewLevelDialog] = useState(false);

    const {currentSelection, setSelection} = useEditorContext();
    const {levels} = useLevelContext();
    const onLevelClicked = (levelId: number) => {
        setSelection(EditorSelectionType.LEVEL, levelId);
    }

    return (<ContextMenu2 style={{height: '100%'}} content={<Menu>
        <MenuItem icon='plus' text='New Level...' onClick={() => null} />
    </Menu>}>
        <Flex flexDir='column' sx={{display: 'flex', flexDirection: 'column'}} height='100%' flex={1}>
            <Menu>
                {levels?.map((level, i) => (
                    <MenuItem key={i} active={currentSelection?.type === EditorSelectionType.LEVEL && i === currentSelection.id} text={level.key} onClick={() => onLevelClicked(i)} />
                ))}
                <MenuItem icon='plus' text='New Level...' onClick={() => setNewLevelDialog(true)}/>
            </Menu>

            {newLevelDialog && <NewLevelDialog isOpen={newLevelDialog} onClose={() => setNewLevelDialog(false)} />}
        </Flex>
    </ContextMenu2>);
}

export default LevelsList;