import { Button, Menu, MenuItem } from "@blueprintjs/core";
import { Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useEditorContext } from "../../../contexts/EditorContext";
import { ActorDef, AnimationKey } from "../../../types";
import SelectAnimationDialog from "./SelectAnimationDialog";

interface ActorAnimationListProps {
    actor: ActorDef;
    updateActor: (newAnimations: AnimationKey[]) => void;
}

const ActorAnimationList = (props: ActorAnimationListProps) => {
    const [addAnimationDialog, setAddAnimationDialog] = useState(false);
    const {actorAnimationPreviewSelection, setActorAnimationPreviewSelection} = useEditorContext();

    const addAnimations = (animKeys: AnimationKey[]) => {
        props.updateActor([...props.actor.animations ?? [], ...animKeys]);
        setAddAnimationDialog(false);
    }

    const removeSelectedAnim = () => {
        props.updateActor(props.actor.animations.filter(r => r !== actorAnimationPreviewSelection));
    }

    return (
    <Flex flexDir='column' sx={{display: 'flex', flexDirection: 'column'}} height='100%' flex={1}>
        <Text fontSize="md">Animations</Text>
        <Menu>
            {props.actor.animations.map((animKey, i) => (
                <MenuItem key={i} active={actorAnimationPreviewSelection === animKey} 
                    text={animKey} onClick={() => setActorAnimationPreviewSelection(animKey)}>
                    
                </MenuItem>
            ))}
        </Menu>
        <Button icon='plus' onClick={() => setAddAnimationDialog(true)}>Add Animation</Button>
        <Button icon='minus' onClick={() => removeSelectedAnim()}>Remove Selected Animation</Button>

        {addAnimationDialog && <SelectAnimationDialog isOpen={addAnimationDialog} onClose={() => setAddAnimationDialog(false)}
            onAnimationsSelected={addAnimations} />}
    </Flex>
  );
}

export default ActorAnimationList;