import { Button } from "@blueprintjs/core";
import { Flex, Stack, StackItem } from "@chakra-ui/layout";
import { Link } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useAssetContext } from "../contexts/AssetContext";
import { EditorSelectionType, useEditorContext } from "../contexts/EditorContext";
import { AssetKey } from "../types";
import DataProcessor from "../utils/DataProcessor";
import SelectAssetDialog from "./Editor/Assets/SelectAssetDialog";
import EditorSettingsDialog from "./Editor/Levels/EditorSettingsDialog";

interface MainLayoutProps {
    children: React.ReactChild
}
const MainLayout = (props: MainLayoutProps) => {
    const [showSelectAssetsToPackage, setShowSelectAssetsToPackage] = useState(false);
    const [showEditorSettings, setShowEditorSettings] = useState(false);
    const {assets} = useAssetContext();
    const {currentSelection} = useEditorContext();
    const importRef = useRef<HTMLInputElement | null>(null);

    const packageAssets = (assetKeys: AssetKey[]) => {
        const selectedAssets = assets.filter((r) => assetKeys.includes(r.key));
        if(selectedAssets.length > 0) {
            DataProcessor.exportAssets(selectedAssets);
        }
        setShowSelectAssetsToPackage(false);
    }

    const exportData = () => {
        if(!localStorage) {return;}
        const assets = localStorage.getItem('assets');
        const actors = localStorage.getItem('actors');
        const animations = localStorage.getItem('animations');
        
        const layerTypes = localStorage.getItem('layerTypes');
        const editor_settings = localStorage.getItem('editor_settings');
        const levels = localStorage.getItem('levels');
        const layers = localStorage.getItem('layers');

        const editorData = {
            assets,
            actors,
            animations,
            layerTypes,
            editor_settings,
            levels,
            layers
        };

        DataProcessor.downloadJson(JSON.stringify(editorData), 'EDITOR_DATA.json');
    }

    const importData = () => {
        if(importRef.current) {
            importRef.current.click();
        }
    }

    const onFileImport = () => {
        if(importRef.current && importRef.current.files?.length === 1) {
            const file = importRef.current.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                const editorData = JSON.parse(reader.result as string);
                localStorage.setItem('assets', editorData.assets);              
                localStorage.setItem('actors', editorData.actors);              
                localStorage.setItem('animations', editorData.animations);
                
                localStorage.setItem('layerTypes', editorData.layerTypes);
                localStorage.setItem('editor_settings', editorData.editor_settings);
                localStorage.setItem('levels', editorData.levels);
                localStorage.setItem('layers', editorData.layers);
                

                window.location.reload();       
            }
            reader.readAsText(file);
        }
    }

    return (
        <Flex minH='100vh' flexDir='column' className='bp3-dark'>
            <Flex p={4} alignItems='center'>
                Sup Playground
                <Link href={"/dist?" + (currentSelection.type === EditorSelectionType.LEVEL ? 'level=' + currentSelection.id : '')} target="_blank">
                    <StackItem>
                        <Button style={{marginLeft: 10}} icon="play" />
                    </StackItem>
                </Link>
                <Button style={{marginLeft: 10}} onClick={() => setShowEditorSettings(true)} icon="wrench" />
                <Button style={{marginLeft: 10}} onClick={() => {localStorage && localStorage.clear(); window.location.reload()}} icon="reset">Reset Editor</Button>
                <Button style={{marginLeft: 10}} onClick={exportData} icon="export">Export Editor Data</Button>
                <Button style={{marginLeft: 10}} onClick={importData} icon="import">Import Editor Data</Button>
                <Flex justifyContent='flex-end' flex={1}>
                    <Stack direction='row' spacing={4}>
                        <StackItem>
                            <Button style={{marginLeft: 10}} icon="box" onClick={() => setShowSelectAssetsToPackage(true)}>Package Assets</Button>
                        </StackItem>
                    </Stack>
                </Flex>
            </Flex>
            <Flex flexDir='column' h='100%' alignItems='center' flex={1}>
                {props.children}
            </Flex>
            <input ref={importRef} type='file' hidden onChange={onFileImport} />
            {showSelectAssetsToPackage && <SelectAssetDialog isOpen={showSelectAssetsToPackage} onClose={() => setShowSelectAssetsToPackage(false)} onAssetsSelected={packageAssets} />}
            {showEditorSettings && <EditorSettingsDialog isOpen={showEditorSettings} onClose={() => setShowEditorSettings(false)} />}
        </Flex>
    );
}

export default MainLayout;