import { Dialog, FileInput } from '@blueprintjs/core';
import { Box, Button, Flex, InputGroup, InputLeftAddon, NumberInput, NumberInputField, Stack } from '@chakra-ui/react';
import { SyntheticEvent } from 'react';
import { useAssetContext } from '../../../contexts/AssetContext';
import { useLevelContext } from '../../../contexts/LevelContext';
import { LayerTypeDef } from '../../../types';
import DataProcessor from '../../../utils/DataProcessor';

// const readFile = (file: any): any => {
//     return new Promise((resolve, reject) => {
//       // Create file reader
//       let reader = new FileReader()
  
//       // Register event listeners
//       reader.addEventListener("loadend", (e: any) => resolve(e.target.result))
//       reader.addEventListener("error", reject)
  
//       // Read file
//       reader.readAsArrayBuffer(file)
//     })
//   }

interface EditorSettingsDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const EditorSettingsDialog = (props: EditorSettingsDialogProps) => {
    const {canvasSize, setCanvasSize} = useLevelContext();

    const {getAssetIndexByKey} = useAssetContext();
    const {layers, getLayerTypeByTag, updateLayerType} = useLevelContext();

    const groundLayerType = getLayerTypeByTag('ground') ?? {
        tag: 'ground', layerOffset: {width: 0, height: 70},
        gridSize: {width: 16, height: 4},
        pixelSize: {width: 32, height: 8},
        assetSlot1: getAssetIndexByKey('bumper'),
        assetSlot2: -1,
        assetSlot3: -1,
    };
    const obstacleLayerType = getLayerTypeByTag('obstacle') ?? {
        tag: 'obstacle', layerOffset: {width: 0, height: 46},
        gridSize: {width: 32, height: 4},
        pixelSize: {width: 16, height: 8},
        assetSlot1: getAssetIndexByKey('rail'),
        assetSlot2: getAssetIndexByKey('mailbox'),
        assetSlot3: getAssetIndexByKey('hydrant')
    }

    const onUploadLevelDataChange = async (e: SyntheticEvent) => {
        // console.log((e.target as any).files);
        // const file = levelDataUploadRef.current.files[0];
        // const byteArray = new Uint8Array(await readFile(file));
        // props.setLevelData(DataProcessor.process2bppBuffer(byteArray, props.gridWidth, props.gridHeight));
    }

    const onDownloadLevelData = async() => {
        console.log(layers[0]);
        console.log(DataProcessor.getPacked2bppBytes(layers[0].assetMap));

        const resultBuffers = [];
        for(const layer of layers) {
            const packedLayerData = DataProcessor.exportLayer(layer);
            resultBuffers.push(packedLayerData);
        }
        // if(lastLayer) {
        //     console.log(`{
        //         export const WIDTH = ${lastLayer.layerSize.width};
        //         export const HEIGHT = ${lastLayer.layerSize.height};
                
        //         export const PIXEL_WIDTH = ${lastLayer.pixelSize.width};
        //         export const PIXEL_HEIGHT = ${lastLayer.pixelSize.height};

        //         export const ASSET_SLOTS = [
        //             ${lastLayer.assetSlot1},
        //             ${lastLayer.assetSlot1},
        //             ${lastLayer.assetSlot1},
        //         ];
        //     }`);
        // }
        console.log(JSON.stringify(resultBuffers));
    }

    const updateOffset = (layerType: LayerTypeDef, val: number, isWidth: boolean) => {
        const updatedType = {...layerType};
        if(isWidth) {
            updatedType.layerOffset.width = val;
        } else {
            updatedType.layerOffset.height = val;
        }
        updateLayerType(updatedType.tag, updatedType);
    }

    const updateLayerSize = (layerType: LayerTypeDef, val: number, isWidth: boolean) => {
        const updatedType = {...layerType};
        if(isWidth) {
            updatedType.gridSize.width = val;
        } else {
            updatedType.gridSize.height = val;
        }
        updateLayerType(updatedType.tag, updatedType);
    }

    const updatePixelSize = (layerType: LayerTypeDef, val: number, isWidth: boolean) => {
        const updatedType = {...layerType};
        if(isWidth) {
            updatedType.pixelSize.width = val;
        } else {
            updatedType.pixelSize.height = val;
        }
        updateLayerType(updatedType.tag, updatedType);
    }


    return (
        <Dialog className='bp3-dark' title='Editor Settings' isOpen={props.isOpen} onClose={props.onClose} style={{width: 600}}>
        <Flex flexDir='column' sx={{display: 'flex', flexDirection: 'column'}} height='100%'>
            <Box overflowY='auto'>
                <Stack m={4}  spacing={4}>
                    <Stack spacing={4} direction='row'>
                        <InputGroup>
                            <InputLeftAddon children="Canvas Width" />
                            <NumberInput value={canvasSize.width} onChange={(sv, v) => !isNaN(v) && setCanvasSize({width: v, height: canvasSize.height})}>
                                <NumberInputField />
                            </NumberInput>
                        </InputGroup>
                        <InputGroup>
                            <InputLeftAddon children="Canvas Height" />
                            <NumberInput value={canvasSize.height} onChange={(sv, v) => !isNaN(v) && setCanvasSize({width: canvasSize.width, height: v})}>
                                <NumberInputField />
                            </NumberInput>
                        </InputGroup>
                    </Stack>

                    <Stack spacing={4} direction='row'>
                        <InputGroup>
                            <InputLeftAddon children="Ground Offset X" />
                            <NumberInput value={groundLayerType.layerOffset.width} onChange={(sv, v) => !isNaN(v) && updateOffset(groundLayerType, v, true)}>
                                <NumberInputField />
                            </NumberInput>
                        </InputGroup>
                        <InputGroup>
                            <InputLeftAddon children="Ground Offset Y" />
                            <NumberInput value={groundLayerType.layerOffset.height} onChange={(sv, v) => !isNaN(v) && updateOffset(groundLayerType, v, false)}>
                                <NumberInputField />
                            </NumberInput>
                        </InputGroup>
                    </Stack>
                    <Stack spacing={4} direction='row'>
                        <InputGroup>
                            <InputLeftAddon children="Ground Layer Width" />
                            <NumberInput value={groundLayerType.gridSize.width} onChange={(sv, v) => !isNaN(v) && updateLayerSize(groundLayerType, v, true)}>
                                <NumberInputField />
                            </NumberInput>
                        </InputGroup>
                        <InputGroup>
                            <InputLeftAddon children="Ground Layer Height" />
                            <NumberInput value={groundLayerType.gridSize.height} onChange={(sv, v) => !isNaN(v) && updateLayerSize(groundLayerType, v, false)}>
                                <NumberInputField />
                            </NumberInput>
                        </InputGroup>
                    </Stack>
                    <Stack spacing={4} direction='row'>
                        <InputGroup>
                            <InputLeftAddon children="Ground Pixel Width" />
                            <NumberInput value={groundLayerType.pixelSize.width} onChange={(sv, v) => !isNaN(v) && updatePixelSize(groundLayerType, v, true)}>
                                <NumberInputField />
                            </NumberInput>
                        </InputGroup>
                        <InputGroup>
                            <InputLeftAddon children="Ground Pixel Height" />
                            <NumberInput value={groundLayerType.pixelSize.height} onChange={(sv, v) => !isNaN(v) && updatePixelSize(groundLayerType, v, false)}>
                                <NumberInputField />
                            </NumberInput>
                        </InputGroup>
                    </Stack>
                    
                    <Stack spacing={4} direction='row'>
                        <InputGroup>
                            <InputLeftAddon children="Obstacle Offset X" />
                            <NumberInput value={obstacleLayerType.layerOffset.width} onChange={(sv, v) => !isNaN(v) && updateOffset(obstacleLayerType, v, true)}>
                                <NumberInputField />
                            </NumberInput>
                        </InputGroup>
                        <InputGroup>
                            <InputLeftAddon children="Obstacle Offset Y" />
                            <NumberInput value={obstacleLayerType.layerOffset.height} onChange={(sv, v) => !isNaN(v) && updateOffset(obstacleLayerType, v, false)}>
                                <NumberInputField />
                            </NumberInput>
                        </InputGroup>
                    </Stack>
                    <Stack spacing={4} direction='row'>
                        <InputGroup>
                            <InputLeftAddon children="Obstacle Layer Width" />
                            <NumberInput value={obstacleLayerType.gridSize.width} onChange={(sv, v) => !isNaN(v) && updateLayerSize(obstacleLayerType, v, true)}>
                                <NumberInputField />
                            </NumberInput>
                        </InputGroup>
                        <InputGroup>
                            <InputLeftAddon children="Obstacle Layer Height" />
                            <NumberInput value={obstacleLayerType.gridSize.height} onChange={(sv, v) => !isNaN(v) && updateLayerSize(obstacleLayerType, v, false)}>
                                <NumberInputField />
                            </NumberInput>
                        </InputGroup>
                    </Stack>
                    <Stack spacing={4} direction='row'>
                        <InputGroup>
                            <InputLeftAddon children="Obstacle Pixel Width" />
                            <NumberInput value={obstacleLayerType.pixelSize.width} onChange={(sv, v) => !isNaN(v) && updatePixelSize(obstacleLayerType, v, true)}>
                                <NumberInputField />
                            </NumberInput>
                        </InputGroup>
                        <InputGroup>
                            <InputLeftAddon children="Obstacle Pixel Height" />
                            <NumberInput value={obstacleLayerType.pixelSize.height} onChange={(sv, v) => !isNaN(v) && updatePixelSize(obstacleLayerType, v, false)}>
                                <NumberInputField />
                            </NumberInput>
                        </InputGroup>
                    </Stack>

                    <Stack spacing={4} direction='row'>
                        <FileInput text="Upload Level Data..." onInputChange={onUploadLevelDataChange} />
                        <Button onClick={onDownloadLevelData} colorScheme='blue'>Download Level Data</Button>
                    </Stack>
                </Stack>
            </Box>
        </Flex>
        </Dialog>
  );
}

export default EditorSettingsDialog;
