import { useEffect, useRef } from 'react';
import { useActorContext } from '../../../contexts/ActorContext';
import { useAssetContext } from '../../../contexts/AssetContext';
import { EditorSelectionType, useEditorContext } from '../../../contexts/EditorContext';
import useCanvas from '../../../hooks/useCanvas';
import { AnimationDef } from '../../../types';

interface AssetImageData {
    name: string;
    image: HTMLImageElement;
    id: number;
};

interface CurrentFrameData {
    frameId: number;
    lastFrameTime: number;
    elapsedFrameTime: number;
}

const ActorPreviewPanel = () => {
    const {currentSelection, actorAnimationPreviewSelection} = useEditorContext();
    const {assets} = useAssetContext();
    const {animations, actors, getAnimationByKey} = useActorContext();

    const frameAssetData = useRef<Record<number, AssetImageData>>({});
    const currentFrameData = useRef<CurrentFrameData>({frameId: 0, elapsedFrameTime: 0, lastFrameTime: performance.now()});

    let selectedAnimation: AnimationDef | null = null;
    if(currentSelection) {
        if(currentSelection.type === EditorSelectionType.ACTOR && actors[currentSelection.id]?.animations) {
            const animIndex = actors[currentSelection.id].animations.indexOf(actorAnimationPreviewSelection);
            const animKey = actors[currentSelection.id].animations[animIndex > -1 ? animIndex : 0];
            selectedAnimation = getAnimationByKey(animKey) ?? null;
        } else if(currentSelection.type === EditorSelectionType.ANIMATION) {
            selectedAnimation = animations[currentSelection.id]
        }
    }
    const animationAssets = selectedAnimation && selectedAnimation.frameAssetKeys && assets.filter((r) => selectedAnimation?.frameAssetKeys.includes(r.key))
        .sort((r1, r2) => (selectedAnimation?.frameAssetKeys.indexOf(r1.key) ?? 0) - (selectedAnimation?.frameAssetKeys.indexOf(r2.key) ?? 0));
    const maxFrameWidth = animationAssets && Math.max.apply(Math, animationAssets.map((r) => r.width));
    const maxFrameHeight = animationAssets && Math.max.apply(Math, animationAssets.map((r) => r.height));

    useEffect(() => {
        const tempFrameData = [];
        if(animationAssets) {
            for(const frameAsset of animationAssets) {
                const frameImg = new Image();
                frameImg.src = frameAsset.imagePreviewUri;

                tempFrameData.push({
                    name: frameAsset.key,
                    image: frameImg,
                    id: tempFrameData.length
                });
            }
        }

        frameAssetData.current = tempFrameData;
    }, [animationAssets])

    const draw = (ctx: CanvasRenderingContext2D, frameCount: number) => {
        if(!selectedAnimation || !maxFrameWidth || !maxFrameHeight) {
            return;
        }
        ctx.canvas.width = maxFrameWidth;
        ctx.canvas.height = maxFrameHeight;

        const curFrameData = currentFrameData.current;
        curFrameData.elapsedFrameTime += (performance.now() - curFrameData.lastFrameTime);
        curFrameData.lastFrameTime = performance.now();
        if(curFrameData.elapsedFrameTime > selectedAnimation.frameDuration * 1000) {
            curFrameData.frameId++;
            curFrameData.elapsedFrameTime = 0;
            if(curFrameData.frameId >= selectedAnimation.frameAssetKeys.length) {
                curFrameData.frameId = 0;
            }

            console.log(frameAssetData.current[curFrameData.frameId]);
        }

        if(frameAssetData.current[curFrameData.frameId] && frameAssetData.current[curFrameData.frameId].image) {
            ctx.drawImage(frameAssetData.current[curFrameData.frameId].image, 0, 0, maxFrameWidth, maxFrameHeight)
        }
    };

    const canvasRef = useCanvas(draw, {
    });

    return (
        <canvas className='actorPreviewCanvas' ref={canvasRef} style={{height:'100%'}} />
    );
}

export default ActorPreviewPanel;
