import { Menu, MenuItem } from "@blueprintjs/core";
import { ContextMenu2 } from "@blueprintjs/popover2";
import { Flex } from "@chakra-ui/react";
import { useState } from "react";
import { useActorContext } from "../../../contexts/ActorContext";
import { EditorSelectionType, useEditorContext } from "../../../contexts/EditorContext";
import NewActorDialog from "./NewActorDialog";

const ActorList = () => {
    const [newActorDialog, setNewActorDialog] = useState(false);
    const {currentSelection, setSelection} = useEditorContext();
    const {actors} = useActorContext();

    const onActorClicked = (actorId: number) => {
        setSelection(EditorSelectionType.ACTOR, actorId);
    }

    return (
        <ContextMenu2 style={{height: '100%'}} content={<Menu>
                <MenuItem icon="plus" text="New Actor..." onClick={() => setNewActorDialog(true)} />
            </Menu>}>
            <Flex flexDir='column' sx={{display: 'flex', flexDirection: 'column'}} height='100%' flex={1}>
                <Menu>
                    {actors.map((actor, i) => (
                        <MenuItem key={i} active={currentSelection?.type === EditorSelectionType.ACTOR && i === currentSelection.id} 
                            text={actor.key} onClick={() => onActorClicked(i)} />
                    ))}
                    <MenuItem icon='plus' text='New Actor...' onClick={() => setNewActorDialog(true)}/>
                </Menu>

                {newActorDialog && <NewActorDialog isOpen={newActorDialog} onClose={() => setNewActorDialog(false)} />}
            </Flex>
        </ContextMenu2>
  );
}

export default ActorList;