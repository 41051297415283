import { Button, HStack, Input, Stack, Tag, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAssetContext } from "../../../contexts/AssetContext";

interface AssetPropertiesProps {
    selectedAssetId: number;
}

const AssetProperties = (props: AssetPropertiesProps) => {
    //We use temp key to store the user edited key. If non-unique will not commit until made unique
    const [tempKey, setTempKey] = useState('');

    const {assets, assetKeyAvailable, updateAsset, removeAsset, exportAsset} = useAssetContext();

    const selectedAsset = assets[props.selectedAssetId];


    useEffect(() => {
        if(selectedAsset) {
            setTempKey(selectedAsset.key);
        } else {
            setTempKey('');
        }
    }, [selectedAsset]);

    if(!selectedAsset) {
        return (<Text>Asset not found AssetID: {props.selectedAssetId}</Text>);
    }

    const updateName = (val: string) => {
        setTempKey(val);
        if(!assetKeyAvailable(val)) { return; } //Do not commit if key collides

        let updatedAsset = selectedAsset;
        updatedAsset.key = val;
        updateAsset(props.selectedAssetId, updatedAsset);
    }

    return (
    <Stack m={4}  spacing={4}>
        <Input placeholder='Key' value={tempKey} onChange={(e) => {updateName(e.target.value)}} isInvalid={(!assetKeyAvailable(tempKey) && tempKey !== selectedAsset.key)} />
        <HStack>
            <Tag>Width: {selectedAsset.width}</Tag>
            <Tag>Height: {selectedAsset.height}</Tag>
        </HStack>
        <Button colorScheme='blue' onClick={() => exportAsset(props.selectedAssetId)}>Export Asset</Button>
        <Button colorScheme='red' onClick={() => removeAsset(props.selectedAssetId)}>Delete Asset</Button>
    </Stack>);
}

export default AssetProperties;