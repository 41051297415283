import * as React from 'react';
import { ActorProvider } from './ActorContext';
import { AssetProvider } from './AssetContext';
import { EditorProvider } from './EditorContext';
import { LevelProvider } from './LevelContext';

interface AppProvidersInterface {
    children: React.ReactChild;
}

const AppProviders = (props: AppProvidersInterface) => {
    return (
        <EditorProvider>
        <AssetProvider>
        <ActorProvider>
        <LevelProvider>
            {props.children}
        </LevelProvider>
        </ActorProvider>
        </AssetProvider>
        </EditorProvider>
    );
};

export default AppProviders;
