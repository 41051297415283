import { Menu, MenuItem, Tab, Tabs } from "@blueprintjs/core";
import { Flex } from "@chakra-ui/layout";
import { useState } from "react";
import { EditorSelectionType, EditorSubSelectionType, useEditorContext } from "../../../contexts/EditorContext";
import { useLevelContext } from "../../../contexts/LevelContext";
import NewLayerDialog from "./NewLayerDialog";

const SkateLayerList = () => {
    return (
    <Tabs className='fullheight' renderActiveTabPanelOnly={true}>
        <Tab id="ground" title='Ground' panel={<LayerList layerTag='ground' />} panelClassName='fullheight' />
        <Tab id="obstacles" title='Obstacles' panel={<LayerList layerTag='obstacle' />} panelClassName='fullheight' />
        <Tab id="all" title='All' panel={<LayerList layerTag='' />} panelClassName='fullheight' />
    </Tabs>);
}

interface LayerListProps {
    layerTag: string;
}

const LayerList = (props: LayerListProps) => {
    const [newLayerDialog, setNewLayerDialog] = useState(false);
    const {layers} = useLevelContext();
    const {currentSelection, setSelection} = useEditorContext();

    const layerEntries = layers.map((r, i) => ({index: i, layer: r}));
    const filteredLayers = props.layerTag === '' ? layerEntries : layerEntries.filter(r => r.layer.tag === props.layerTag);

    const onLayerClicked = (layerIndex: number) => {
        //Either we use currently selected level, or select dummy level (-1)
        let selectedLevelId = currentSelection.type === EditorSelectionType.LEVEL ? currentSelection.id : -1;
        setSelection(EditorSelectionType.LEVEL, selectedLevelId, EditorSubSelectionType.LAYER, layerIndex);
    }

    return (<Flex flexDir='column' sx={{display: 'flex', flexDirection: 'column'}} height='100%' flex={1}>
    <Menu>
        {filteredLayers.map((layer, i) => (
            <MenuItem key={i} active={currentSelection.subType === EditorSubSelectionType.LAYER && layer.index === currentSelection.subId} text={layer.layer.key} onClick={() => onLayerClicked(layer.index)} />
        ))}
        {props.layerTag !== '' && <MenuItem icon='plus' text='New Layer...' onClick={() => setNewLayerDialog(true)}/>}
    </Menu>

    {newLayerDialog && <NewLayerDialog tag={props.layerTag} isOpen={newLayerDialog} onClose={() => setNewLayerDialog(false)} />}
</Flex>);
}

export default SkateLayerList;