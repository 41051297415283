import { ReactChild } from 'react';
import './App.css';

import {ExpandButton, Mosaic, MosaicWindow} from 'react-mosaic-component';

import 'react-mosaic-component/react-mosaic-component.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';

import MainLayout from './MainLayout';
import AssetPanel from './Editor/Assets/AssetPanel';
import PropertiesPanel from './Editor/Properties/PropertiesPanel';
import MainCanvasPanel from './Editor/MainCanvasPanel';
import LevelsList from './Editor/Levels/LevelsList';
import SkateLayerList from './Editor/Levels/SkateLayerList';

export type ViewId = 'level' | 'assets' | 'properties' | 'layers' | 'levels';

interface PanelDef {
    name: string;
    component: ReactChild;
}

const PANEL_MAP: Record<ViewId, PanelDef> = {
    level: {name: 'Editor', component: <MainCanvasPanel /> },
    assets: {name: 'Asset Browser', component: <AssetPanel /> },
    properties: {name: 'Properties', component: <PropertiesPanel />},
    levels: {name: 'Levels', component: <LevelsList />},
    layers: {name: 'Layers', component: <SkateLayerList />}
};

function App() {
    return (
    <div id="app" className="App">
        <MainLayout>
            <Mosaic<ViewId>
                className='editor mosaic-blueprint-theme bp3-dark'
                renderTile={(id, path) => (
                    <MosaicWindow<ViewId> toolbarControls={[<ExpandButton />]} path={path} title={PANEL_MAP[id].name}>
                        {PANEL_MAP[id].component}
                    </MosaicWindow>
                )}
                initialValue={{
                    direction: 'row',
                    first: {
                        direction: 'column',
                        first: 'level',
                        second: {
                            direction: 'row',
                            first: 'levels',
                            second: 'layers',
                            splitPercentage: 50
                        },
                        splitPercentage: 80
                    },
                    second: {
                        direction: 'column',
                        first: 'assets',
                        second: 'properties'
                    },
                    splitPercentage: 80
                }}
            />
        </MainLayout>
    </div>
  );
}

export default App;
