import { Dialog } from "@blueprintjs/core";
import { Image, Grid, GridItem, Text, VStack, Button } from "@chakra-ui/react";
import { useState } from "react";
import { useAssetContext } from "../../../contexts/AssetContext";
import { AssetKey } from "../../../types";

interface SelectAssetDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onAssetsSelected: (assetKeys: AssetKey[]) => void;
}

const SelectAssetDialog = (props: SelectAssetDialogProps) => {
    const {assets} = useAssetContext();
    const [hoveredId, setHoveredId] = useState(-1);
    const [selectedAssetKeys, setSelectedAssetKeys] = useState<AssetKey[]>([]);


    const onAssetClicked = (key: string) => {
        const existingIndex = selectedAssetKeys.indexOf(key);
        if(existingIndex >= 0) {
            setSelectedAssetKeys(selectedAssetKeys => selectedAssetKeys.filter(r => r !== key));
        } else {
            setSelectedAssetKeys(selectedAssetKeys => [...selectedAssetKeys, key]);
        }
    }

    const onMouseEnter = (id: number) => {
        setHoveredId(id);
    }

    const onMouseExit = (id: number) => {
        if(hoveredId === id) {
            setHoveredId(-1);
        }
    }

    const confirmSelection = () => {
        props.onAssetsSelected(selectedAssetKeys);
    }

    return (
        <Dialog className='bp3-dark' title='Select Assets' isOpen={props.isOpen} onClose={props.onClose}>
            <Grid templateColumns="repeat(5, 1fr)" gap={2}>
                {assets.map((r, index) => (
                    <GridItem display='flex' onClick={() => onAssetClicked(r.key)} key={index}
                        style={{border: hoveredId === index || selectedAssetKeys.indexOf(r.key) >= 0 ? '4px black solid' : 'none'}}
                        onMouseEnter={() => onMouseEnter(index)} onMouseLeave={() => onMouseExit(index)}>
                        <VStack>
                        <Image m='auto' w='100%' maxH='200px' maxW='200px' src={r.imagePreviewUri} objectFit='cover' />
                        <Text>{r.key}</Text>
                        </VStack>
                    </GridItem>
                ))}
            </Grid>
            <Button onClick={confirmSelection}>Select Assets</Button> 
        </Dialog>
    );
}

export default SelectAssetDialog;