import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {ChakraProvider, ColorModeScript} from '@chakra-ui/react';
import App from './components/App';
import AppProviders from './contexts';
import theme from './theme';

if(localStorage && !localStorage.getItem('chakra-ui-color-mode')) {
  localStorage.setItem('chakra-ui-color-mode', 'dark');
}

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
      <AppProviders>
        <App />
      </AppProviders>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
