import React, { useState } from "react";
import { LayerDef, LayerKey, LayerTypeDef, LevelDef, SizeDef } from "../types";
import { getInitialStorage } from "../utils/Helpers";

interface LevelContextInterface {
    canvasSize: SizeDef;
    setCanvasSize: (val: SizeDef) => void;

    levels: LevelDef[];
    createLevel: (level: LevelDef) => void;
    updateLevel: (levelIndex: number, level: LevelDef) => void;
    removeLevel: (levelIndex: number) => void;
    cloneLevel: (levelIndex: number) => void;

    layers: LayerDef[];
    getLayerByKey: (key: string) => LayerDef | undefined;
    createLayer: (layer: LayerDef) => void;
    updateLayer: (layerIndex: number, layer: LayerDef) => void;
    removeLayer: (layerKey: LayerKey) => void;
    cloneLayer: (layerKey: LayerKey) => void;

    layerTypes: LayerTypeDef[];
    getLayerTypeByTag: (tag: string) => LayerTypeDef | undefined;
    updateLayerType: (tag: string, layerType: LayerTypeDef) => void;
}

const LevelContext = React.createContext<LevelContextInterface>({
    canvasSize: {width: 0, height: 0},
    setCanvasSize: (val: SizeDef) => {},

    levels: [],
    getLayerByKey: ()=>undefined,
    createLevel: ()=>{},
    updateLevel: ()=>{},
    removeLevel: ()=>{},
    cloneLevel: ()=>{},

    layers: [],
    createLayer: ()=>{},
    updateLayer: ()=>{},
    removeLayer: ()=>{},
    cloneLayer: ()=>{},

    layerTypes: [],
    getLayerTypeByTag: () => undefined,
    updateLayerType: ()=>{}
});
LevelContext.displayName = 'LevelContext';

export const LevelProvider = (props: any) => {
    const [canvasSize, setCanvasSize] = useState({width: 1024, height: 1024});
    const [levels, setLevels] = useState<LevelDef[]>(getInitialStorage('levels'));
    const [layers, setLayers] = useState<LayerDef[]>(getInitialStorage('layers'));
    const [layerTypes, setLayerTypes] = useState<LayerTypeDef[]>(getInitialStorage('layerTypes'));

    React.useEffect(() => {
        localStorage.setItem('levels', JSON.stringify(levels));
    }, [levels]);
    
    React.useEffect(() => {
        localStorage.setItem('layers', JSON.stringify(layers));
    }, [layers]);

    React.useEffect(() => {
        localStorage.setItem('layerTypes', JSON.stringify(layerTypes));
    }, [layerTypes])

    const value = React.useMemo(() => {
        const getLayerByKey = (key: string) => {
            return layers.find(r => r.key === key);
        }

        const createLayer = (layer: LayerDef) => {
            setLayers(prevLayers => [...prevLayers, layer]);
        }

        const updateLayer = (layerIndex: number, layer: LayerDef) => {
            const updatedLayers = layers.slice();
            updatedLayers[layerIndex] = {...layer};
            setLayers(updatedLayers);
        }

        const removeLayer = (layerKey: LayerKey) => {
            setLayers(layers.filter((r) => r.key !== layerKey));
        }

        const cloneLayer = (layerKey: LayerKey) => {
            const layer = getLayerByKey(layerKey);
            if(layer) {
                const clonedLayer = JSON.parse(JSON.stringify(layer));
                clonedLayer.name += ' CLONE ' + layers.length;
                setLayers(prevLayers => [...prevLayers, clonedLayer]); 
            }
        }

        const createLevel = (level: LevelDef) => {
            setLevels(prevLevels => [...prevLevels, level]);
        }
        const updateLevel = (levelIndex: number, level: LevelDef) => {
            const updatedLevels = levels.slice();
            updatedLevels[levelIndex] = level;
            setLevels(updatedLevels);
        }
        const removeLevel = (levelIndex: number) => {
            setLevels(levels.filter((r, i) => i !== levelIndex));
        }
        const cloneLevel = (levelIndex: number) => {
            const clonedLevel = JSON.parse(JSON.stringify(levels[levelIndex]));
            clonedLevel.key += '_cloned_' + levels.length;
            setLevels(prevLevels => [...prevLevels, clonedLevel]);
        }

        const getLayerTypeByTag = (tag: string) => {
            return layerTypes.find(r => r.tag === tag);
        }

        const updateLayerType = (tag: string, layerType: LayerTypeDef) => {
            const updatedLayerTypes = layerTypes.slice();
            const layerTypeIndex = layerTypes.findIndex(r => r.tag === tag);
            if(layerTypeIndex > -1) {
                updatedLayerTypes[layerTypeIndex] = {...layerType};
                setLayerTypes(updatedLayerTypes);
            } else {
                updatedLayerTypes.push(layerType);
                setLayerTypes(updatedLayerTypes);
            }
        }

        return ({ canvasSize, setCanvasSize, layers, getLayerByKey, createLayer, updateLayer, removeLayer, cloneLayer, 
            levels, createLevel, updateLevel, removeLevel, cloneLevel,
            layerTypes, getLayerTypeByTag, updateLayerType});
    }, [canvasSize, layers, levels, layerTypes]);

    return <LevelContext.Provider value={value} {...props} />
}

export const useLevelContext = () => {
    return React.useContext(LevelContext);
}