import { Dialog } from "@blueprintjs/core";
import { Button, Input, Stack } from "@chakra-ui/react";
import { useState } from "react";
import { useLevelContext } from "../../../contexts/LevelContext";

interface NewLayerDialogProps {
    isOpen: boolean;
    onClose: () => void
}

const NewLevelDialog = (props: NewLayerDialogProps) => {
    const [levelKey, setLevelKey] = useState('');
    const {createLevel} = useLevelContext();

    const onCreateLevel = () => {
        createLevel({
            key: levelKey,
            groundLayerKey: '',
            obstacleLayerKey: ''
        });
        props.onClose();
    }
    return (<Dialog className='bp3-dark' title='New Level' isOpen={props.isOpen} onClose={props.onClose}>
    <Stack spacing={4} m={5}>
        <Input placeholder='Name' value={levelKey} onChange={(e) => {setLevelKey(e.target.value)}} />
        <Button onClick={onCreateLevel} disabled={levelKey === ''}>
            Create Level
        </Button>
    </Stack>
</Dialog>);
}

export default NewLevelDialog;