import { Dialog, FileInput } from "@blueprintjs/core";
import { Button, Input, Stack, Tag, Image, HStack } from "@chakra-ui/react";
import { SyntheticEvent, useState } from "react";
import { useAssetContext } from "../../../contexts/AssetContext";
import { AssetDef } from "../../../types";
import { ProcessImage } from "../../../utils/ImageProcessor";

interface NewAssetDialogProps {
    isOpen: boolean;
    onClose: () => void
}

const NewAssetDialog = (props: NewAssetDialogProps) => {
    const [assetKey, setAssetKey] = useState<string>('');
    const [uploadedData, setUploadedData] = useState<AssetDef | null>(null);

    const {importAsset, assetKeyAvailable} = useAssetContext();

    const keyCollides = !assetKeyAvailable(assetKey);

    const onUploadImage = (e: SyntheticEvent) => {
        const files = (e.target as any).files;
        if(files.length !== 1) {
            return;
        }
        const file = files[0];
        ProcessImage(file, (data) => {
            setUploadedData(data);
            if(assetKey === '') {
                setAssetKey(data.key);
            }
        });
    }

    const onImportAsset = () => {
        if(uploadedData) {
            let asset = uploadedData;
            asset.key = assetKey;
            importAsset(asset);
            props.onClose();
        }
    }

    return (
        <Dialog className='bp3-dark' title='Import Asset' isOpen={props.isOpen} onClose={props.onClose}>
            <Stack spacing={4} m={5}>
                <Input placeholder='Key' value={assetKey} onChange={(e) => {setAssetKey(e.target.value)}} isInvalid={keyCollides}  />
                <FileInput text="Upload Asset Image..." onInputChange={onUploadImage} inputProps={{accept: '.jpg,.png,.bmp'}} />
                {uploadedData && <>
                    <Image src={uploadedData.imagePreviewUri} objectFit='cover' />
                    <HStack>
                        <Tag>Width: {uploadedData?.width}</Tag>
                        <Tag>Height: {uploadedData?.height}</Tag>
                    </HStack>
                </>}
                <Button onClick={onImportAsset} disabled={!uploadedData || keyCollides}>
                    Import Asset
                </Button>
            </Stack>
        </Dialog>
    );
}

export default NewAssetDialog;